import localForage from 'localforage';

class CachingEngine {
  public static async setData(key: string, data: any): Promise<boolean> {
    return localForage
      .setItem(key, data)
      .then(() => {
        console.debug('Data has been saved!');
        return true;
      })
      .catch((err: any) => {
        console.error('An error occurred:', err);
        return false;
      });
  }

  public static async getData(key: string): Promise<any> {
    return localForage
      .getItem(key)
      .then((value: any) => {
        console.debug('Retrieved data:', value);
        return value;
      })
      .catch((err: any) => {
        console.error('An error occurred:', err);
        return null;
      });
  }

  public static async deleteData(key: string): Promise<any> {
    try {
      // Check if the item exists first
      const value = await localForage.getItem(key);

      if (value !== null) {
        // If the item exists, delete it
        await localForage.removeItem(key);
        console.debug(`Item with key "${key}" deleted successfully.`);
        return value; // Return the deleted value (if needed)
      } else {
        console.debug(`No item found with key "${key}".`);
        return null; // Return null if item does not exist
      }
    } catch (err) {
      console.error('An error occurred while deleting data:', err);
      return null; // Return null in case of any error
    }
  }

  public static async clearCache(): Promise<boolean> {
    return localForage
      .clear()
      .then(() => {
        console.log('cache cleared');
        return true;
      })
      .catch((err: any) => {
        console.error('An error occurred:', err);
        return false;
      });
  }
}

export default CachingEngine;
