import React, { ReactNode, useState } from 'react';
import { DisplayMissedWord } from 'utils/types';
import ClassComparisonChart from './student/ClassComparisonChart';
import TeacherGradebook from 'models/TeacherGradebook';
import MissedWordGraph from './MissedWordGraph';
import ClassroomGrowthChart from './classroom/ClassroomGrowthChart';

interface ClassroomAnalyticsProps {
  missedWords: DisplayMissedWord[];
  gradebook: TeacherGradebook;
}

const ClassroomAnalytics: React.FC<ClassroomAnalyticsProps> = ({
  missedWords,
  gradebook,
}) => {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const tabLabels: string[] = ['Overall', 'Improvement', 'Missed Words'];
  const tabContents: ReactNode[] = [
    <ClassComparisonChart gradebook={gradebook} />,
    <ClassroomGrowthChart gradebook={gradebook} />,
    <MissedWordGraph missedWords={missedWords} />,
  ];

  return (
    <div className="graph-container">
      <div className="student-insights">
        <div className="tab-headers">
          {tabLabels.map((label, idx) => (
            <span
              key={`tab-header-label-${idx}-${label}`}
              className={`tab-header-label ${
                idx === selectedIdx ? 'active-label' : ''
              }`}
              onClick={() => setSelectedIdx(idx)}
            >
              {label}
            </span>
          ))}
        </div>
        {tabContents[selectedIdx]}
      </div>
    </div>
  );
};

export default ClassroomAnalytics;
