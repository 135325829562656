import englishContent from 'content/en/en.json';

class ContentService {
  private static instance: ContentService;
  private content: any = englishContent;
  private currLang = process.env.LANG || 'en';
  private contentMap: Record<string, any> = {
    en: englishContent,
  };

  constructor() {
    if (Object.keys(this.contentMap).includes(process.env.LANG as string)) {
      this.content = this.contentMap[this.currLang];
    }
  }

  public static get(): ContentService {
    if (!ContentService.instance) {
      ContentService.instance = new ContentService();
    }
    return ContentService.instance;
  }

  getAuthConstants(): any {
    return this.content['auth']['constants'];
  }

  getCommonForms(): any {
    return this.content['common'];
  }

  getCatalogPage(): any {
    return this.content['catalog'];
  }

  getPaymentPage(): any {
    return this.content['auth']['payment'];
  }

  getLandingPage(): any {
    return this.content['landing'];
  }

  getLoginPage(): any {
    return this.content['auth']['login'];
  }

  getSignupPage(): any {
    return this.content['auth']['signup'];
  }

  getPasswordResetPage(): any {
    return this.content['auth']['password_reset'];
  }

  getForgotPasswordPage(): any {
    return this.content['auth']['forgot_password'];
  }

  getSubscriptionPage(): any {
    return this.content['auth']['subscription'];
  }

  getContactPage(): any {
    return this.content['contact'];
  }

  getStudentForms(): any {
    return this.content['student'];
  }

  getAssignmentSubmissionPage(): any {
    return this.content['student']['assignment'];
  }

  getStudentClassroomPage(): any {
    return this.content['student']['classroom'];
  }

  getStudentHomePage(): any {
    return this.content['student']['home'];
  }

  getStudentProfilePage(): any {
    return this.content['student']['profile'];
  }

  getTeacherForms(): any {
    return this.content['teacher'];
  }

  getTeacherAnalyticsPage(): any {
    return this.content['teacher']['analytics'];
  }

  getTeacherAssignmentPage(): any {
    return this.content['teacher']['assignment'];
  }

  getTeacherClassroomPage(): any {
    return this.content['teacher']['classroom'];
  }

  getTeacherHomePage(): any {
    return this.content['teacher']['home'];
  }

  getTeacherProfilePage(): any {
    return this.content['teacher']['profile'];
  }

  getTeacherConstants(): any {
    return this.content['teacher']['constants'];
  }

  getProgressReportContent(language: string): any {
    // console.log(
    //   Object.keys(this.content['teacher']['analytics']['progressReport']),
    // );
    // console.log(language);
    return this.content['teacher']['analytics']['progressReport'][language];
  }
}

const contentService = ContentService.get();
export default contentService;
