import Quill from 'quill';

interface ImageResizeOptions {
  // optional: maxWidth?: number;
  // optional: minWidth?: number;
  // optional: maxHeight?: number;
  // optional: minHeight?: number;
}

type DragHandlePosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

interface DragHandleData {
  el: HTMLDivElement;
  position: DragHandlePosition;
}

class ImageResize {
  private quill: Quill;
  private options: ImageResizeOptions;
  private overlay?: HTMLDivElement;
  private currentImage?: HTMLImageElement;

  // We'll store the container so we can append the overlay there
  // By default, we'll use `this.quill.root.parentElement` (the .ql-container)
  // but you can change it if your scrollable container is different.
  private containerEl: HTMLElement;

  private dragHandles: DragHandleData[] = [];
  private currentHandle?: DragHandleData; // which corner is active?

  // for drag calculations
  private startX = 0;
  private startY = 0;
  private startWidth = 0;
  private startHeight = 0;

  constructor(quill: Quill, options: ImageResizeOptions = {}) {
    this.quill = quill;
    this.options = options;

    // Determine the container in which we'll place overlays
    // Typically, .ql-container is the parent of .ql-editor, so:
    this.containerEl = this.quill.root.parentElement as HTMLElement;
    // Ensure containerEl is position: relative (either via CSS or forced here):
    // this.containerEl.style.position = 'relative';

    // Bind event listeners
    this.quill.root.addEventListener('click', this.handleClick);
    document.addEventListener('mousedown', this.handleMousedown);
    document.addEventListener('mousemove', this.handleMousemove);
    document.addEventListener('mouseup', this.handleMouseup);
  }

  // ----------------------------------------
  //  EVENT HANDLERS
  // ----------------------------------------

  private handleClick = (evt: MouseEvent) => {
    const target = evt.target as HTMLElement;
    if (target && target.tagName === 'IMG') {
      // Show overlay on the clicked image
      this.showOverlay(target as HTMLImageElement);
    } else if (this.overlay) {
      // Clicked somewhere else - remove overlay
      this.removeOverlay();
    }
  };

  private handleMousedown = (evt: MouseEvent) => {
    // Check if the user clicked on one of the drag handles
    const handleData = this.dragHandles.find((h) => h.el === evt.target);
    if (handleData && this.currentImage) {
      evt.preventDefault(); // prevent default image drag
      this.currentHandle = handleData;

      // record initial mouse position and image dimension
      this.startX = evt.clientX;
      this.startY = evt.clientY;
      this.startWidth = this.currentImage.offsetWidth;
      this.startHeight = this.currentImage.offsetHeight;

      document.body.style.userSelect = 'none'; // prevent text selection
    }
  };

  private handleMousemove = (evt: MouseEvent) => {
    if (!this.currentHandle || !this.currentImage) return;

    // If user is holding the mouse down (evt.buttons === 1)
    if (evt.buttons === 1) {
      const deltaX = evt.clientX - this.startX;
      const deltaY = evt.clientY - this.startY;

      let newWidth = this.startWidth;
      let newHeight = this.startHeight;

      // Adjust width/height depending on which corner is being dragged
      switch (this.currentHandle.position) {
        case 'top-left':
          newWidth = this.startWidth - deltaX;
          newHeight = this.startHeight - deltaY;
          break;
        case 'top-right':
          newWidth = this.startWidth + deltaX;
          newHeight = this.startHeight - deltaY;
          break;
        case 'bottom-left':
          newWidth = this.startWidth - deltaX;
          newHeight = this.startHeight + deltaY;
          break;
        case 'bottom-right':
          newWidth = this.startWidth + deltaX;
          newHeight = this.startHeight + deltaY;
          break;
      }

      // Optionally clamp the values
      const minWidth = 20;
      const minHeight = 20;

      // For example, you might clamp max width to the container's width:
      const containerWidth = this.containerEl.offsetWidth;
      const maxWidth = containerWidth;

      newWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
      newHeight = Math.max(newHeight, minHeight);

      // Apply to image
      this.currentImage.style.width = newWidth + 'px';
      this.currentImage.style.height = newHeight + 'px';

      // Re-position the overlay
      this.positionOverlay();
    }
  };

  private handleMouseup = () => {
    document.body.style.userSelect = ''; // re-enable selection
    this.currentHandle = undefined;

    // Force Quill to recognize "something" changed:
    const range = this.quill.getSelection();
    if (range) {
      // Insert a zero-width space, then remove it
      this.quill.insertText(range.index, '\u200B', 'user');
      this.quill.deleteText(range.index, 1, 'user');
    }
  };

  // ----------------------------------------
  //  OVERLAY & HANDLE METHODS
  // ----------------------------------------

  private showOverlay(imageEl: HTMLImageElement) {
    this.removeOverlay(); // clean up any existing overlay
    this.currentImage = imageEl;

    // Create overlay
    this.overlay = document.createElement('div');
    this.overlay.style.position = 'absolute';
    this.overlay.style.border = '1px dashed #aaa';
    this.overlay.style.zIndex = '1000';

    // Append overlay to the container (not the document body!)
    this.containerEl.appendChild(this.overlay);

    // Create the 4 corner handles
    this.dragHandles = [
      { position: 'top-left', el: document.createElement('div') },
      { position: 'top-right', el: document.createElement('div') },
      { position: 'bottom-left', el: document.createElement('div') },
      { position: 'bottom-right', el: document.createElement('div') },
    ];

    this.dragHandles.forEach((handleData) => {
      const handle = handleData.el;
      handle.style.width = '8px';
      handle.style.height = '8px';
      handle.style.backgroundColor = 'white';
      handle.style.border = '1px solid #777';
      handle.style.borderRadius = '50%';
      handle.style.position = 'absolute';
      handle.style.zIndex = '1001';
      handle.style.cursor = 'nwse-resize';

      this.overlay!.appendChild(handle);
    });

    this.positionOverlay();
  }

  private positionOverlay() {
    if (!this.currentImage || !this.overlay) return;

    // Grab bounding boxes
    const containerRect = this.containerEl.getBoundingClientRect();
    const imageRect = this.currentImage.getBoundingClientRect();

    // Calculate offset within the container
    // We factor in containerEl.scrollTop/scrollLeft in case it's scrollable
    const offsetX =
      imageRect.left - containerRect.left + this.containerEl.scrollLeft;
    const offsetY =
      imageRect.top - containerRect.top + this.containerEl.scrollTop;

    // Position overlay to cover the image within the container
    this.overlay.style.left = offsetX + 'px';
    this.overlay.style.top = offsetY + 'px';
    this.overlay.style.width = imageRect.width + 'px';
    this.overlay.style.height = imageRect.height + 'px';

    // Position each handle
    this.dragHandles.forEach(({ position, el }) => {
      // Clear out all top/left/right/bottom first
      el.style.left = '';
      el.style.top = '';
      el.style.right = '';
      el.style.bottom = '';

      switch (position) {
        case 'top-left':
          el.style.left = '-4px'; // offset handle partially outside the overlay border
          el.style.top = '-4px';
          break;
        case 'top-right':
          el.style.right = '-4px';
          el.style.top = '-4px';
          break;
        case 'bottom-left':
          el.style.left = '-4px';
          el.style.bottom = '-4px';
          break;
        case 'bottom-right':
          el.style.right = '-4px';
          el.style.bottom = '-4px';
          break;
      }
    });
  }

  private removeOverlay() {
    if (this.overlay && this.overlay.parentNode) {
      this.overlay.parentNode.removeChild(this.overlay);
    }
    this.overlay = undefined;
    this.dragHandles = [];
    this.currentHandle = undefined;
    this.currentImage = undefined;
  }
}

export default ImageResize;
