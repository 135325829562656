import React, { useEffect, useState } from 'react';
import { UserInput } from 'view/components/common/UserInput';
import Question from 'models/Question';
import ActionButton from 'view/components/buttons/ActionButton';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import useApiCall from 'contexts/ApiCall';
import { adminService } from 'services/adminService';
import Book from 'models/Book';
import AssignmentQuestion from './questions/AssignmentQuestion';
import { nanoid } from 'nanoid';

import 'view/style/teacher/components/assignment/questionEditor.css';

type DefaultQuestion = {
  text: string;
  book: string;
  choices: any[];
};

interface QuestionEditorProps {
  book?: Book;
  questions: Question[];
  defaultQuestions?: DefaultQuestion[];
  isAutoGenerateAllowed: boolean;
  setIsAutoGenerateAllowed: (val: boolean) => void;
  onCreateQuestion: (
    text: string,
    choices?: MultipleChoiceOption[],
    index?: number,
  ) => void;
  onUpdateQuestion: (question: Question, submit?: boolean) => void;
  onDeleteQuestion: (question: Question) => void;
  onExit: () => void;
}

export const QuestionEditor: React.FC<QuestionEditorProps> = ({
  book,
  questions,
  defaultQuestions,
  isAutoGenerateAllowed,
  setIsAutoGenerateAllowed,
  onCreateQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
  onExit,
}) => {
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [editorQuestions, setEditorQuestions] = useState<Question[]>([]);
  const makeApiCall = useApiCall();

  useEffect(() => {
    setEditorQuestions(questions);
  }, [questions]);

  const handleQuestionTextChange = (question: Question, text: string) => {
    const newQuestion = Question.fromQuestion(question);
    newQuestion.text = text;
    onUpdateQuestion(newQuestion, false);
  };

  const handleCreateQuestion = () => {
    if (newQuestionText === '') return;
    onCreateQuestion(newQuestionText);
    setNewQuestionText('');
  };

  const handleAddQuestionChoice = (question: Question) => {
    const newQuestion = Question.fromQuestion(question);
    newQuestion.choices = [
      ...(question.choices ?? []),
      new MultipleChoiceOption(
        nanoid().slice(0, 8),
        question.id,
        '',
        false,
        (newQuestion.choices?.length ?? 0) + 1,
      ),
    ];
    onUpdateQuestion(newQuestion, false);
  };

  const handleModifyQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => {
    const updatedChoice = new MultipleChoiceOption(
      choice.id,
      choice.question,
      text,
      correct,
      choice.index,
      choice.img,
      choice.img_file,
    );
    const newQuestion = Question.fromQuestion(question);
    const choiceIdx = question.choices?.findIndex(
      (c) => c.id === choice.id,
    ) as number;
    newQuestion.choices = [
      ...question.choices!.slice(0, choiceIdx),
      updatedChoice,
      ...question.choices!.slice(choiceIdx + 1),
    ];

    onUpdateQuestion(newQuestion, false);
  };

  const handleDeleteQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
  ) => {
    const newQuestion = Question.fromQuestion(question);
    newQuestion.choices = [
      ...question.choices!.filter((c) => c.id !== choice.id),
    ].sort((c1, c2) => c1.index - c2.index);

    newQuestion.choices.forEach((choice, index) => {
      choice.index = index + 1; // Set the index to 1-based
    });

    onUpdateQuestion(newQuestion, false);
  };

  const handleSelectDefaults = () => {
    defaultQuestions?.forEach((dq) => {
      onCreateQuestion(
        dq.text,
        dq.choices.map((c, index) => {
          return { ...c, index: index };
        }),
      );
    });
  };

  const handleAutoGenerateQuestions = () => {
    if (!book) return;
    console.log('auto generating');
    makeApiCall(adminService.generateDefaultQuestions, book.getText())
      .then((resp) => {
        if (!resp.questions) {
          console.log(resp);
        } else {
          resp.questions.forEach((q: Record<string, string>, idx: number) => {
            onCreateQuestion(q.text, undefined, questions.length + idx + 1);
          });
          setIsAutoGenerateAllowed(false);
        }
      })
      .catch((err) => alert(err));
  };

  const handleSave = () => {
    // just resave every question
    editorQuestions.forEach((q) => onUpdateQuestion(q));
    onExit();
  };

  return (
    <div className="question-editor">
      <label className="label-large">Edit Questions</label>
      {editorQuestions.map((_, idx) => {
        const question = editorQuestions.find((q) => q.index === idx + 1);
        if (!question) return null;
        return (
          <AssignmentQuestion
            key={`assignment-question-${idx}`}
            question={question}
            onQuestionTextChange={handleQuestionTextChange}
            onModifyQuestionChoice={handleModifyQuestionChoice}
            onDeleteQuestionChoice={handleDeleteQuestionChoice}
            onAddQuestionChoice={handleAddQuestionChoice}
            onDeleteQuestion={onDeleteQuestion}
            onUpdateQuestion={onUpdateQuestion}
          />
        );
      })}
      <UserInput
        id="add-question"
        label="Add Question"
        type="textarea"
        value={newQuestionText}
        onChange={(event) => setNewQuestionText(event.target.value)}
      />
      <div className="action-buttons">
        <ActionButton
          type="edit"
          onClick={handleCreateQuestion}
          label="Add Question"
        />
        {defaultQuestions && defaultQuestions.length ? (
          <ActionButton onClick={handleSelectDefaults} label="Show Defaults" />
        ) : null}
        {isAutoGenerateAllowed && (
          <ActionButton
            onClick={handleAutoGenerateQuestions}
            label="Auto Generate"
          />
        )}
        <ActionButton type="go" onClick={handleSave} label="Save changes" />
      </div>
    </div>
  );
};
