import React, { useEffect, useRef, useState } from 'react';
import Book from 'models/Book';
import BookCard from 'view/components/catalog/BookCard';

import 'view/style/catalog/components/bookRow.css';

const arrow_drop_down: string =
  require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

interface BookRowProps {
  label: string;
  books: Book[];
  hasNextPage: boolean;
  loadNextPage: () => Promise<void>;
  onSelect: (book: Book) => void;
}

export const BookRow: React.FC<BookRowProps> = ({
  label,
  books,
  hasNextPage,
  onSelect,
  loadNextPage,
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const rowRef = useRef<HTMLDivElement>(null);
  const lastBookRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!lastBookRef.current || !hasNextPage || isLoading) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting && expand) {
        // Mark that we are now fetching
        setIsLoading(true);
        loadNextPage().finally(() => {
          // Once loadNextPage is done (whether success or error),
          // set isLoading back to false
          setIsLoading(false);
        });
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1.0,
    });
    observer.observe(lastBookRef.current);

    return () => observer.disconnect();
  }, [books, expand, hasNextPage, isLoading, loadNextPage]);

  return (
    <div className="book-catalog-row-container">
      <div className="row" onClick={() => setExpand((prev) => !prev)}>
        <label className="label-large">{label}</label>
        <img src={expand ? arrow_drop_down : arrow_right} alt="dropdown" />
      </div>
      <div
        className={`book-catalog-row${expand ? ' expand' : ''}`}
        ref={rowRef}
      >
        {books.map((book, index) => {
          const isLast = index === books.length - 1;
          return (
            <div
              key={`${label}-book-card-${book.id}`}
              ref={isLast ? lastBookRef : undefined}
            >
              <BookCard book={book} onSelect={onSelect} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
