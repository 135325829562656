import Question from 'models/Question';
import QuestionResponse from 'models/QuestionResponse';
import React from 'react';

import 'view/style/common/tables.css';

interface QuestionsTableProps {
  questions: Question[];
  responses: Record<string, Record<string, any>>;
}

const QuestionsTable: React.FC<QuestionsTableProps> = ({
  questions,
  responses,
}) => {
  console.log(questions);
  return (
    <table className="missed-words-table">
      <thead>
        <tr>
          <th>
            <span className="label-medium">Question</span>
          </th>
          {questions.map((q) => (
            <th key={`question-header-${q.index}`}>
              <span className="label-medium">{q.index}</span>
            </th>
          ))}
        </tr>
        <tr>
          <th>
            <span className="label-medium">Max points / Correct choice</span>
          </th>
          {questions.map((q) => (
            <th key={`question-header-${q.index}`}>
              <span className="label-medium">
                {q.choices && q.choices.length > 0
                  ? String.fromCharCode(
                      64 + q.choices.filter((c) => c.correct)[0].index,
                    )
                  : q.max_points}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(responses).map((studentId: string) => (
          <tr key={`tr-${studentId}`}>
            <td>
              <span className="label-medium">
                {responses[studentId].displayName}
              </span>
            </td>
            {responses[studentId].responses.map(
              (questionResponse: QuestionResponse | undefined, idx: number) => (
                <td key={`${studentId}-${idx}`}>
                  {questionResponse ? (
                    <span
                      className={`label-medium ${
                        (questionResponse?.grade ?? 0) >= 65
                          ? 'label-hint'
                          : 'danger'
                      }`}
                    >
                      {questions[idx].choices &&
                      questions[idx].choices!.length > 0
                        ? questionResponse.choice_index
                          ? String.fromCharCode(
                              64 + questionResponse.choice_index,
                            )
                          : 'X'
                        : questionResponse.grade}
                    </span>
                  ) : (
                    <span className="label-medium danger">X</span>
                  )}
                </td>
              ),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default QuestionsTable;
