import Book from 'models/Book';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AudioRecorder from 'view/components/common/AudioRecorder';
import parse from 'html-react-parser';
import BookCompletedFooter from './BookCompletedFooter';
import useApiCall from 'contexts/ApiCall';
import ReadingUtils from 'utils/ReadingUtils';
import MissedWord from 'models/MissedWord';
import ReadingFrame from 'view/components/common/ReadingFrame';
import aiService from 'services/aiService';

import 'view/style/student/components/assignment/listeningAssessment.css';
import Button from 'view/components/buttons/Button';

interface SpeakingAssessmentProps {
  book: Book;
  isReading: boolean;
  startIndex?: number;
  missedWords?: MissedWord[];
  teacherView?: boolean;
  setIsReading: (isReading: boolean) => void;
  onStopReading: (wordIndex: number) => void;
  onCompletion: () => void;
  onMissedWord: (word: string, index: number, known: boolean) => void;
  onViewQuestions?: () => void;
}

const SpeakingAssessment: React.FC<SpeakingAssessmentProps> = ({
  book,
  isReading,
  startIndex = 0,
  missedWords,
  teacherView = false,
  setIsReading,
  onStopReading,
  onCompletion,
  onMissedWord,
  onViewQuestions,
}) => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [words, setWords] = useState<string[]>([]);
  const [wordIndex, setWordIndex] = useState(0);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null); // State to store the audio blob in case of an error
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const makeApiCall = useApiCall();

  useEffect(() => {
    const targetElement = document.getElementById((wordIndex - 1).toString());
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [htmlContent, wordIndex, isReading]);

  useEffect(() => {
    if (!words.length || wordIndex > 0 || !missedWords) return;
    if (startIndex > 0) {
      // mark the reading up until the current word
      const missedWordIdxs: number[] = missedWords.map(
        (word) => word.word_index,
      );
      for (let i = 0; i < startIndex; i += 1) {
        highlightWord(!missedWordIdxs.includes(i), i);
      }
    }
    // eslint-disable-next-line
  }, [htmlContent, words, startIndex, missedWords, wordIndex]);

  useEffect(() => {
    if (!book || words.length > 0 || !book.html_content) return;
    const bookWords = book.words ?? book.getWords();
    setHtmlContent(ReadingUtils.createHtmlString(bookWords, book.html_content));
    setWords(bookWords);
  }, [book, words.length]);

  useEffect(() => {
    if (!wordIndex || wordIndex === 0) return;
    if (wordIndex >= words.length - 1) {
      setIsCompleted(true);
      onCompletion();
    } else {
      onStopReading(wordIndex);
    }
  }, [wordIndex, words.length, onCompletion, onStopReading]);

  const submitAudio = async (audioBlob: Blob) => {
    makeApiCall(aiService.convertSpeechToText, audioBlob, true)
      .then((resp) => evaluateAssignment(resp))
      .catch((error) => {
        setError(error.message); // Set the error message
        setAudioBlob(audioBlob); // Store the audio blob to allow resubmission
      });
  };

  const evaluateAssignment = (convertedSpeech: string) => {
    let spokenWords: string[] = convertedSpeech.toLowerCase().split(' ');
    let referenceWords: string[] = words.slice(wordIndex);
    let results: Record<string, Record<string, any>>[] = [];
    console.log(spokenWords);
    console.log(referenceWords);
    // create a hashmap of the reference words mapping
    // the word to its indices in the reference text
    let referenceMap: Record<string, number[]> = {};
    for (let i = 0; i < referenceWords.length; i++) {
      const word = referenceWords[i];
      if (word in referenceMap) {
        referenceMap[word].push(i);
      } else {
        referenceMap[word] = [i];
      }
    }

    let currentReferenceIndex = 0;
    let currentSpokenIndex = 0;

    while (currentSpokenIndex < spokenWords.length) {
      // get the first word
      const currentSpokenWord: string = spokenWords[currentSpokenIndex];
      const currentReferenceWord: string =
        referenceWords[currentReferenceIndex];
      // console.log(`
      // ###################
      //   currentSpokenWord=${currentSpokenWord}
      //   currentSpokenIndex=${currentSpokenIndex}
      //   currentReferenceIndex=${currentReferenceIndex}
      //   currentReferenceWord=${referenceWords[currentReferenceIndex]}
      //   referenceMap[currentSpokenWord]=${referenceMap[currentSpokenWord]}
      //   referenceMap=${JSON.stringify(referenceMap)}
      // ###################
      // `);
      // if this index is the index of the next word in the reading
      // then mark it correct
      if (ReadingUtils.soundsSimilar(currentSpokenWord, currentReferenceWord)) {
        results.push({
          [currentReferenceWord]: {
            correct: true,
            index: wordIndex + currentReferenceIndex,
          },
        });
        // remove this index from the reference map for this word
        referenceMap[currentReferenceWord].shift();
        currentReferenceIndex++;
        currentSpokenIndex++;
      } else {
        // loop through the next 6 spoken words and take the min
        // index from the refernce map. If there is no min index
        // (meaning none of the words are in the reading), then go
        // until the next valid word is found and mark all words between
        // the two as missed

        // There is a case where we do not want to do this, and that is if the
        // last spoken word is not the current word, AND the reference word we are looking
        // at is not the last word in the text
        if (
          currentSpokenIndex === spokenWords.length - 1 &&
          currentReferenceIndex < referenceWords.length - 1
        )
          break;

        let minReferenceIdx = Infinity;
        let nextSpokenIdx = currentSpokenIndex;
        let nextWordDistanceThreshold = 6;
        for (let i = 0; i < nextWordDistanceThreshold; i++) {
          if (currentSpokenIndex + i < spokenWords.length) {
            const nextSpokenWord: string = spokenWords[currentSpokenIndex + i];
            // console.log(`nextSpokenWord=${nextSpokenWord}`);
            if (referenceMap[nextSpokenWord]) {
              // console.log(referenceMap[nextSpokenWord][0]);
              if (referenceMap[nextSpokenWord][0] < minReferenceIdx)
                if (minReferenceIdx === Infinity) {
                  minReferenceIdx = referenceMap[nextSpokenWord][0];
                  nextSpokenIdx = currentSpokenIndex + i + 1;
                } else if (
                  referenceMap[nextSpokenWord][0] !== currentSpokenIndex
                ) {
                  // here we need to handle the condition where we see a future
                  // occurence of the word we are looking for, but have already
                  // seen a word that comes before that future occurence. In that
                  // case the future occurence should not be considered as the user
                  // having said this word
                  minReferenceIdx = referenceMap[nextSpokenWord][0];
                  nextSpokenIdx = currentSpokenIndex + i + 1;
                }
            }
            if (i === nextWordDistanceThreshold - 1) {
              nextWordDistanceThreshold += 1;
            }
          }
        }
        // console.log(`Moving on to word ${referenceWords[minReferenceIdx]}`);
        // console.log(currentReferenceIndex, minReferenceIdx);
        if (
          minReferenceIdx !== Infinity &&
          nextSpokenIdx > currentSpokenIndex
        ) {
          // mark all words between the two as missed
          for (let i = currentReferenceIndex; i < minReferenceIdx; i++) {
            results.push({
              [referenceWords[i]]: {
                correct: false,
                index: wordIndex + i,
              },
            });
            referenceMap[referenceWords[i]].shift();
          }
          referenceMap[referenceWords[minReferenceIdx]].shift();
          results.push({
            [referenceWords[minReferenceIdx]]: {
              correct: true,
              index: wordIndex + minReferenceIdx,
            },
          });
          currentSpokenIndex = nextSpokenIdx;
          currentReferenceIndex = minReferenceIdx + 1;
        } else {
          // mark all the rest of the words in the reading as wrong
          for (let i = currentReferenceIndex; i < referenceWords.length; i++) {
            results.push({
              [referenceWords[i]]: {
                correct: false,
                index: wordIndex + i,
              },
            });
          }
          break;
        }
      }
    }

    // mark up the reading
    for (let i = 0; i < results.length; i++) {
      const word = Object.keys(results[i])[0] as string;
      highlightWord(results[i][word].correct, results[i][word].index);
    }

    // now submit all missed words
    try {
      for (let i = 0; i < results.length; i++) {
        const word = Object.keys(results[i])[0] as string;
        if (!results[i][word].correct) {
          // prevent making duplicates
          if (
            missedWords?.findIndex(
              (mw) =>
                mw.word === word && mw.word_index === results[i][word].index,
            ) === -1
          )
            onMissedWord(word, results[i][word].index, true);
        }
      }
    } catch (error) {
      console.error(error);
    }

    console.log(results);
    return results;
  };

  const highlightWord = useCallback(
    (isCorrect: boolean, wordIndex: number) => {
      const wordNode = containerRef.current?.querySelector('.brw.current');
      if (wordNode) {
        wordIndex = Number(wordNode.id.split('-')[1]);
        wordNode.className = `brw${isCorrect ? ' read' : ' incorrect'}`;
      }

      const nextWordNode = containerRef.current?.querySelector(
        `#word-${wordIndex + 1}`,
      );
      if (nextWordNode) {
        nextWordNode.className = `brw current`;
      }

      setWordIndex(wordIndex + 1);
    },
    [containerRef, setWordIndex],
  );

  return (
    <div className="reading-container">
      <ReadingFrame refLink={containerRef}>{parse(htmlContent)}</ReadingFrame>
      {isCompleted || teacherView ? (
        <BookCompletedFooter onViewQuestions={onViewQuestions} />
      ) : (
        <div className="row">
          <AudioRecorder
            onStartRecording={() => setIsReading(true)}
            onStopRecording={() => setIsReading(false)}
            onSubmit={submitAudio}
          />
          {error && audioBlob && (
            <div>
              <span className="label-small danger">Error: {error}</span>
              <Button
                label="Resubmit"
                text="resubmit"
                onClick={() => submitAudio(audioBlob)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SpeakingAssessment;
