import React, { useEffect, useMemo, useState } from 'react';
import teacherService from 'services/teacherService';
import useApiCall from 'contexts/ApiCall';
// import contentService from "services/contentService";
import { SearchBar } from 'view/components/common/SearchBar';
import User from 'models/User';
import { useClassroomContext } from 'contexts/ClassroomContext';
import UserInfoDropdownItem from 'view/components/teacher/classroom/UserInfoDropdownItem';
import MultiTabForm from 'view/components/common/MultiTabForm';
import { useUserContext } from 'contexts/UserContext';
import { Subscription, TeacherGeneratedStudent } from 'utils/types';
import InputTable from 'view/components/common/InputTable';
import cleverService from 'services/cleverService';
import ActionButton from 'view/components/buttons/ActionButton';
import googleApiService from 'services/googleAPIService';

interface AddStudentFormProps {
  ignoreStudents: Set<string>;
  onStudentAdded: () => void;
  onClose: () => void;
}

const AddStudentForm: React.FC<AddStudentFormProps> = ({
  ignoreStudents,
  onStudentAdded,
  onClose,
}) => {
  const { user, updateUser } = useUserContext();
  const { classroom } = useClassroomContext();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [students, setStudents] = useState<User[]>();
  const [importRows, setImportRows] = useState<TeacherGeneratedStudent[]>();
  // const formContent = contentService.getTeacherForms().create_classroom_form;
  const makeApiCall = useApiCall();

  useEffect(() => {
    handleSearchStudents('');
    // eslint-disable-next-line
  }, []);

  const handleAddStudent = (
    userId: string,
    classroomId: string,
    readingGroup: number,
  ) => {
    makeApiCall(teacherService.addStudent, userId, classroomId, readingGroup)
      .then(() => {
        setErrorMessage('');
        onStudentAdded();
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const handleSearchStudents = (searchTerm: string) => {
    makeApiCall(teacherService.searchStudents, searchTerm)
      .then((resp) => {
        setStudents(resp);
        setErrorMessage('');
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const createStudents = async (
    studentData: TeacherGeneratedStudent[],
  ): Promise<boolean[]> => {
    try {
      const promiseResults = await Promise.allSettled(
        studentData.map((student) => createStudent(student)),
      );

      // Map the results to a boolean array based on success or failure
      return promiseResults.map((result) => result.status === 'fulfilled');
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      setErrorMessage(`An unexpected error occurred: ${error}`);
      // If there's a critical error, return an array of `false` to keep all rows in the table
      return studentData.map(() => false);
    }
  };

  const createStudent = async (student: TeacherGeneratedStudent) => {
    setErrorMessage('');
    return makeApiCall(
      teacherService.createStudent,
      { ...student, readingLevel: classroom?.min_reading_level },
      classroom?.getId(),
      user?.subscription,
    )
      .then((resp) => {
        updateUser((currentUser) =>
          User.fromServerUser({
            ...currentUser,
            subscription: {
              ...(currentUser?.subscription as Subscription),
              num_users:
                (currentUser?.subscription as Subscription).num_users + 1,
            },
          }),
        );
        return resp; // Return the response for successful fulfillment
      })
      .catch((error) => {
        alert(error.message);
        setErrorMessage('Failed to create some students');
        throw error; // Rethrow to ensure the promise is rejected in case of an error
      });
  };

  const handleImportCleverStudents = () => {
    if (!classroom?.clever_id || !cleverService.isAuthenticated()) return;
    // add existing orator students to this classroom, add the rows here for non existing users
    makeApiCall(cleverService.getClassroomStudents, classroom.clever_id)
      .then((resp) => setImportRows(resp))
      .catch((error) => alert(error));
  };

  const handleImportGoogleStudents = () => {
    if (!classroom?.google_id || !googleApiService.isAuthenticated()) return;
    // add existing orator students to this classroom, add the rows here for non existing users
    makeApiCall(
      googleApiService.getGoogleClassroomStudents,
      classroom.google_id,
    )
      .then((resp) => setImportRows(resp))
      .catch((error) => alert(error));
  };

  const addableStudents: User[] = useMemo(
    () =>
      students?.filter((student) => !ignoreStudents.has(student.username)) ??
      [],
    [students, ignoreStudents],
  );

  if (!classroom || !user) return null;

  return (
    <>
      <MultiTabForm
        labels={['Add students', 'Create students']}
        formContent={[
          <SearchBar
            showDropdown={addableStudents.length > 0}
            onSearch={handleSearchStudents}
          >
            {addableStudents.map((student) => (
              <UserInfoDropdownItem
                key={`user-info-${student.username}`}
                user={student}
                classroom={classroom}
                onSelect={handleAddStudent}
              />
            ))}
          </SearchBar>,
          <div className="form-contents add-student">
            {user.subscription?.max_users && (
              <span className="label-normal">
                ({user.subscription.max_users - user.subscription.num_users}{' '}
                students remaining on your subscription)
              </span>
            )}
            <InputTable
              headers={[
                { key: 'firstName', label: 'First Name', type: 'text' },
                { key: 'lastName', label: 'Last Name', type: 'text' },
                { key: 'email', label: 'Email', type: 'text' },
                { key: 'username', label: 'Username', type: 'text' },
                { key: 'password', label: 'Password', type: 'text' },
              ]}
              importRows={importRows}
              maxRows={
                user.subscription?.max_users &&
                user.subscription.max_users - user.subscription.num_users
              }
              onSubmit={createStudents}
            />
          </div>,
        ]}
      />
      {user.clever_id && cleverService.isAuthenticated() && (
        <ActionButton
          onClick={handleImportCleverStudents}
          label="Import from Clever"
        />
      )}
      {classroom.google_id && googleApiService.isAuthenticated() && (
        <ActionButton
          onClick={handleImportGoogleStudents}
          label="Import from Google"
        />
      )}
      {errorMessage && (
        <span className="label-normal danger">{errorMessage}</span>
      )}
    </>
  );
};

export default AddStudentForm;
