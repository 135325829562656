import React, { useEffect, useState } from 'react';
import { FrameProps } from './MultipartAssignment';
import useApiCall from 'contexts/ApiCall';
import aiService from 'services/aiService';
import CachingEngine from 'utils/CachingEngine';
import QuestionResponse from 'models/QuestionResponse';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import SpeechBubble from 'view/components/common/SpeechBubble';
import AudioRecorder from 'view/components/common/AudioRecorder';
import QuestionFeedback from './QuestionFeedback';

const wormy_a: string = require('assets/images/logos/wormy-1.png');

const ConversationFrame: React.FC<FrameProps> = ({
  question,
  response,
  teacherView = false,
  showFeedback = true,
  updateResponse,
}) => {
  // eslint-disable-next-line
  const [isReading, setIsReading] = useState<boolean>(false);
  const [audioSrc, setAudioSrc] = useState();
  const makeApiCall = useApiCall();

  useEffect(() => {
    // check the student's short answer response
    if (question.check && !teacherView) {
      makeApiCall(
        aiService.gradeShortAnswer,
        question.text,
        response.response,
        question.image_url,
      )
        .then((resp) =>
          handleUpdateResponse(
            response.response,
            resp.correct,
            resp.feedback,
            false,
          ),
        )
        .catch((error) => alert(error));
    }

    if (question.text && !audioSrc) {
      CachingEngine.getData(`${question.index}-audio`).then((cachedAudio) => {
        if (cachedAudio) {
          setAudioSrc(cachedAudio);
        } else {
          makeApiCall(aiService.convertTextToSpeech, question.text)
            .then((resp) => {
              setAudioSrc(resp);
              CachingEngine.setData(`${question.index}-audio`, resp);
            })
            .catch((error) => alert(error));
        }
      });
    }

    // eslint-disable-next-line
  }, [question.check, question.text, teacherView, makeApiCall]);

  const submitAudio = async (audioBlob: Blob) => {
    makeApiCall(aiService.convertSpeechToText, audioBlob)
      .then((resp) => handleUpdateResponse(resp, false))
      .catch((error) => alert(error));
  };

  const handleUpdateResponse = (
    responseText: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = updatedResponse.completed || isAttempt;
    updatedResponse.response = responseText;

    updateResponse(updatedResponse);
  };

  const handleGradeResponse = (grade: number, feedback: string) => {
    if (!teacherView) return;

    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = grade;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="row">
        {question.question_type === 'conversation' ? (
          <label className="label-medium">{question.text}</label>
        ) : (
          <img className="wormy" src={wormy_a} alt="wormy" />
        )}
        <AudioAssistanceButton text={question.text} audioSrc={audioSrc} />
      </div>
      <div className="row spaced">
        {question.image_url && (
          <div className="book-card-cover">
            <img src={question.image_url} alt="failed to load" />
          </div>
        )}
        {response.response && <SpeechBubble text={response.response} />}
      </div>
      {!teacherView && (
        <AudioRecorder
          onStartRecording={() => setIsReading(true)}
          onStopRecording={() => setIsReading(false)}
          onSubmit={submitAudio}
        />
      )}
      {(teacherView ||
        (!teacherView && response.completed && showFeedback)) && (
        <QuestionFeedback
          feedback={response.feedback ?? ''}
          grade={response.grade}
          teacherView={teacherView}
          onGradeResponse={handleGradeResponse}
        />
      )}
    </div>
  );
};

export default ConversationFrame;
