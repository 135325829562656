import React, { useEffect, useState } from 'react';
import BarChart from './BarChart';
import { DisplayMissedWord } from 'utils/types';
import {
  ChartData,
  defaultMissedWordChartData,
} from 'view/pages/teacher/analytics/analytics';
import Assignment from 'models/Assignment';
import useApiCall from 'contexts/ApiCall';
import teacherService from 'services/teacherService';
import Question from 'models/Question';
// import ActionButton from 'view/components/buttons/ActionButton';
import QuestionAnalytics from './questions/QuestionAnalytics';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';

interface AssignmentAnalyticsProps {
  missedWords: DisplayMissedWord[];
  assignment: Assignment;
  students: TeacherGradebookEntry[];
}

const AssignmentAnalytics: React.FC<AssignmentAnalyticsProps> = ({
  missedWords,
  assignment,
  students,
}) => {
  const [numDisplayWords, setNumDisplayWords] = useState<number>(10);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData[]>(
    defaultMissedWordChartData,
  );
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionView] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!assignment) return;
    // get the questions for the assignment, store the responses by student
    makeApiCall(teacherService.getAssignmentQuestions, assignment.id)
      .then((resp) => setQuestions(resp))
      .catch((error) => alert(error));
  }, [assignment, makeApiCall]);

  useEffect(() => {
    if (!missedWords) return;
    setChartLabels(
      missedWords.slice(0, numDisplayWords).map((dmw) => dmw.word),
    );
    setChartData((prevChartData) => [
      {
        ...prevChartData[0],
        data: missedWords.map((dmw) => dmw.missed),
      },
      {
        ...prevChartData[1],
        data: missedWords.map((dmw) => dmw.unknown),
      },
    ]);
  }, [numDisplayWords, missedWords]);

  const handleKeyWordToggle = (checked: boolean) => {
    if (!missedWords) return;
    // get the words we will be displaying
    const displayMissedKeyWords = missedWords
      .filter((dmw) => dmw.isKeyWord || !checked)
      .slice(0, numDisplayWords);
    setChartLabels(displayMissedKeyWords.map((mw) => mw.word));
    setChartData((prevChartData) => [
      {
        ...prevChartData[0],
        data: displayMissedKeyWords.map((dmw) => dmw.missed),
      },
      {
        ...prevChartData[1],
        data: displayMissedKeyWords.map((dmw) => dmw.unknown),
      },
    ]);
  };

  return (
    <>
      <div className="graph-container">
        {questionView ? (
          <QuestionAnalytics questions={questions} students={students} />
        ) : (
          <>
            <BarChart
              options={{}}
              data={{
                labels: chartLabels ?? [],
                datasets: chartData,
              }}
            />
            <div className="row">
              <div className="labeled-input label-left">
                <label># Words</label>
                <input
                  type="number"
                  value={numDisplayWords}
                  onChange={(event) =>
                    setNumDisplayWords(
                      Math.max(
                        Math.min(
                          Number(event.target.value),
                          missedWords.length,
                        ),
                        1,
                      ),
                    )
                  }
                />
              </div>
              <label>Key words only</label>
              <input
                type="checkbox"
                onChange={(event) => handleKeyWordToggle(event.target.checked)}
              />
            </div>
          </>
        )}
      </div>
      {/* {assignment.num_questions > 0 && (
        <ActionButton
          type="edit"
          label={questionView ? 'Assignment' : 'Questions'}
          onClick={() => setQuestionView((prev) => !prev)}
        />
      )} */}
    </>
  );
};

export default AssignmentAnalytics;
