import React, { useState } from 'react';
import Button from 'view/components/buttons/Button';

const play_circle_filled: string =
  require('assets/icons/play_circle_filled.svg').default;
const pause_circle: string = require('assets/icons/pause_circle.svg').default;

interface AudioRecorderProps {
  onStartRecording: () => void;
  onStopRecording: () => void;
  onSubmit: (audioBlob: Blob) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({
  onStartRecording,
  onStopRecording,
  onSubmit,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);
    let chunks: Blob[] = []; // Local variable to store audio chunks

    recorder.ondataavailable = (e) => {
      chunks.push(e.data); // Push new chunk directly to the array
      console.log('Data available:', e.data);
    };

    recorder.onstop = () => {
      console.log('Recording stopped');
      const audioBlob = new Blob(chunks, { type: 'audio/webm' });
      console.log('Audio Blob size:', audioBlob.size); // Log the size of the blob
      onSubmit(audioBlob); // Submit the entire audio blob
      stopRecording();
      onStopRecording();
    };

    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
    onStartRecording();
  };

  const stopRecording = () => {
    if (!mediaRecorder) return;
    setIsRecording(false);
    mediaRecorder.stop();
    mediaRecorder.stream.getAudioTracks().forEach((track) => {
      track.stop();
    });
  };

  return (
    <div className="row">
      {!isRecording ? (
        <Button
          type="go"
          onClick={startRecording}
          text="Start"
          leadIcon={play_circle_filled}
          label="Start"
        />
      ) : (
        <Button
          type="default"
          onClick={stopRecording}
          text="Stop"
          leadIcon={pause_circle}
          label="Stop"
        />
      )}
    </div>
  );
};

export default AudioRecorder;
