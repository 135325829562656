// TODO: Refactor this garbage code, once we confirm that
// teachers actually want this and will buy our product,
// we'll set this up properly server side

import TeacherGradebookEntry from 'models/TeacherGradebookEntry';

// Helper: Given a Date and granularity, return a string key.
function getGroupKey(
  date: Date,
  granularity: 'daily' | 'weekly' | 'monthly',
): string {
  switch (granularity) {
    case 'daily':
      // e.g. "2023-01-25"
      return date.toISOString().substring(0, 10);
    case 'weekly': {
      // Use the Monday of the week as the key.
      const monday = getStartOfWeek(date);
      // You could also format this as "YYYY-Wxx" if desired.
      return monday.toISOString().substring(0, 10);
    }
    case 'monthly':
      // e.g. "2023-01"
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}`;
    default:
      return date.toISOString().substring(0, 10);
  }
}

// Helper: Returns the Monday of the week for a given date (ISO week).
function getStartOfWeek(date: Date): Date {
  // In JavaScript, getDay() returns 0 for Sunday, 1 for Monday, etc.
  // For ISO weeks we want Monday as the first day.
  const day = date.getDay();
  // If day is 0 (Sunday), adjust to previous Monday (i.e. -6 days),
  // otherwise subtract (day - 1) days.
  const diff = day === 0 ? -6 : 1 - day;
  const monday = new Date(date);
  monday.setDate(date.getDate() + diff);
  monday.setHours(0, 0, 0, 0);
  return monday;
}

// Helper: Get the start of the period (day, week, or month) for iteration.
function getPeriodStart(
  date: Date,
  granularity: 'daily' | 'weekly' | 'monthly',
): Date {
  switch (granularity) {
    case 'daily': {
      // Normalize to midnight
      const d = new Date(date);
      d.setHours(0, 0, 0, 0);
      return d;
    }
    case 'weekly':
      return getStartOfWeek(date);
    case 'monthly':
      return new Date(date.getFullYear(), date.getMonth(), 1);
    default:
      return date;
  }
}

export interface TemporalData {
  grade: number;
  completion_score: number;
  correctness_score: number;
  key_word_accuracy_score: number;
  completion_time: number;
  numEntries: number;
}

// Assuming TeacherGradebookEntry has a property assignment_submissions,
// where each submission includes date_completed, grade, completion_score, etc.
export const getGrowthData = (
  students: TeacherGradebookEntry[],
  granularity: 'daily' | 'weekly' | 'monthly' = 'daily',
) => {
  if (!students) return {};
  // Object to hold aggregated averages keyed by the group key.
  const aggregatedData: { [groupKey: string]: TemporalData } = {};

  // We also track the overall min and max dates from the submissions.
  let minDate: Date | null = null;
  let maxDate: Date | null = null;

  // 1. Group submissions by the chosen period (day, week, or month) and calculate averages.
  students.forEach((student) =>
    student.assignment_submissions.forEach((sa) => {
      const submittedDateStr = sa.date_completed;
      if (!submittedDateStr) return;

      const submittedDate = new Date(submittedDateStr);
      // Update the min and max dates.
      if (minDate === null || submittedDate < minDate) minDate = submittedDate;
      if (maxDate === null || submittedDate > maxDate) maxDate = submittedDate;

      // Compute the grouping key based on the granularity.
      const groupKey = getGroupKey(submittedDate, granularity);

      if (!aggregatedData[groupKey]) {
        aggregatedData[groupKey] = {
          grade: sa.grade,
          completion_score: sa.completion_score,
          correctness_score: sa.correctness_score,
          key_word_accuracy_score: sa.key_word_accuracy_score,
          completion_time: sa.completion_time,
          numEntries: 1,
        };
      } else {
        const current = aggregatedData[groupKey];
        const { numEntries } = current;
        current.grade =
          (current.grade * numEntries + sa.grade) / (numEntries + 1);
        current.completion_score =
          (current.completion_score * numEntries + sa.completion_score) /
          (numEntries + 1);
        current.correctness_score =
          (current.correctness_score * numEntries + sa.correctness_score) /
          (numEntries + 1);
        current.key_word_accuracy_score =
          (current.key_word_accuracy_score * numEntries +
            sa.key_word_accuracy_score) /
          (numEntries + 1);
        current.completion_time =
          (current.completion_time * numEntries + sa.completion_time) /
          (numEntries + 1);
        current.numEntries += 1;
      }
    }),
  );

  if (minDate === null || maxDate === null) {
    console.error('No submission data available.');
    return {};
  }

  // 2. Determine the period boundaries for filling in missing data.
  let currentPeriod = getPeriodStart(minDate, granularity);
  const endPeriod = getPeriodStart(maxDate, granularity);

  const filledData: { [groupKey: string]: TemporalData } = {};
  let previousData: TemporalData | null = null;

  // 3. Iterate over each period from the start to the end.
  while (currentPeriod <= endPeriod) {
    const key = getGroupKey(currentPeriod, granularity);
    if (aggregatedData[key]) {
      // If we have data for this period, use it and update previousData.
      previousData = aggregatedData[key];
      filledData[key] = previousData;
    } else if (previousData) {
      // Otherwise, carry forward the most recent data.
      filledData[key] = { ...previousData };
    }
    // Advance currentPeriod by the appropriate increment.
    switch (granularity) {
      case 'daily':
        currentPeriod.setDate(currentPeriod.getDate() + 1);
        break;
      case 'weekly':
        currentPeriod.setDate(currentPeriod.getDate() + 7);
        break;
      case 'monthly':
        currentPeriod.setMonth(currentPeriod.getMonth() + 1);
        break;
      default:
        currentPeriod.setDate(currentPeriod.getDate() + 1);
    }
  }

  return filledData;
};

export enum AverageGranularityOption {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}
