import Question from 'models/Question';
import React, { useEffect, useState } from 'react';
import QuestionsTable from './QuestionsTable';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import QuestionResponse from 'models/QuestionResponse';
import useApiCall from 'contexts/ApiCall';
import teacherService from 'services/teacherService';

interface QuestionAnalyticsProps {
  questions: Question[];
  students: TeacherGradebookEntry[];
}

const QuestionAnalytics: React.FC<QuestionAnalyticsProps> = ({
  questions,
  students,
}) => {
  // const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [responses, setResponses] =
    useState<Record<string, Record<string, any>>>(); // mapping of users to their question responses
  const makeApiCall = useApiCall();

  useEffect(() => {
    const fetchResponses = async () => {
      // Initialize an empty object to store the updated responses
      const updatedResponses: Record<string, Record<string, any>> = {};

      // Use Promise.all to make sure all API calls are done before updating the state
      await Promise.all(
        questions.map(async (question) => {
          const questionResponses = await makeApiCall(
            teacherService.getQuestionResponses,
            question.id,
          );

          students.forEach((student) => {
            const studentResponse = questionResponses.find(
              (qr: QuestionResponse) =>
                Number(qr.student_profile_id) ===
                Number(student.student_profile.id),
            );

            // Initialize array if it doesn't exist for this student
            if (!updatedResponses[student.student_profile.id]) {
              updatedResponses[student.student_profile.id] = { responses: [] };
            }

            // Push the studentResponse (it can be undefined if not found)
            updatedResponses[student.student_profile.id].responses.push(
              studentResponse,
            );
            updatedResponses[student.student_profile.id].displayName =
              student.displayName();
          });
        }),
      );

      // Now update the state with the updated responses
      setResponses(updatedResponses);
    };

    fetchResponses();
  }, [students, questions, makeApiCall]);

  // const handleQuestionSelected = (
  //   event: React.ChangeEvent<HTMLSelectElement>,
  // ) => {
  //   const selected = event.target.value;
  //   if (selected === 'all') setSelectedQuestion(undefined);
  //   else setSelectedQuestion(questions[Number(event.target.value)]);
  // };

  return (
    <>
      {/* <div className="row">
        Question:
        <select onChange={handleQuestionSelected}>
          <option value="all">All</option>
          {questions.map((q) => (
            <option key={`question-select-${q.id}`} value={q.index}>
              {q.index}
            </option>
          ))}
        </select>
      </div> */}
      {responses ? (
        <QuestionsTable questions={questions} responses={responses} />
      ) : (
        <>Loading responses...</>
      )}
      {/* {selectedQuestion.choices && selectedQuestion.choices.length > 0 && <></>}
      {(!selectedQuestion.choices || selectedQuestion.choices.length === 0) &&
        selectedQuestion.question_type in
          [
            QuestionType.RESPOND,
            QuestionType.CONVERSATION,
            QuestionType.SHORTANSWER,
            QuestionType.LISTENING,
            QuestionType.TRANSCRIBE,
          ] && <></>}
      {selectedQuestion.question_type === QuestionType.READALOUD && <></>} */}
    </>
  );
};

export default QuestionAnalytics;
