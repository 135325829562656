import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import contentService from 'services/contentService';
import TeacherGradebook from 'models/TeacherGradebook';
import { getGrowthData } from 'utils/analytics';

interface ClassroomGrowthChartProps {
  gradebook: TeacherGradebook;
}

export enum AverageGranularityOption {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

const ClassroomGrowthChart: React.FC<ClassroomGrowthChartProps> = ({
  gradebook,
}) => {
  const [granularity, setGranularity] = useState<AverageGranularityOption>(
    AverageGranularityOption.weekly,
  );
  const content = contentService.getProgressReportContent('English');

  const temporalChartData = useMemo<ChartData<'line'>>(() => {
    const temporalStudentData = getGrowthData(gradebook.students, granularity);
    const sortedTemporalKeys = Object.keys(temporalStudentData).sort();
    return {
      labels: sortedTemporalKeys,
      datasets: [
        {
          label: content.temporalChart.labels[0],
          data: sortedTemporalKeys.map((k) => temporalStudentData[k].grade),
          borderColor: 'blue',
          backgroundColor: 'blue',
          tension: 0.1,
        },
        {
          label: content.temporalChart.labels[1],
          data: sortedTemporalKeys.map(
            (k) => temporalStudentData[k].completion_score,
          ),
          borderColor: 'orange',
          backgroundColor: 'orange',
          tension: 0.1,
        },
        {
          label: content.temporalChart.labels[2],
          data: sortedTemporalKeys.map(
            (k) => temporalStudentData[k].correctness_score,
          ),
          borderColor: 'green',
          backgroundColor: 'green',
          tension: 0.1,
        },
        {
          label: content.temporalChart.labels[3],
          data: sortedTemporalKeys.map(
            (k) => temporalStudentData[k].key_word_accuracy_score,
          ),
          borderColor: 'purple',
          backgroundColor: 'purple',
          tension: 0.1,
        },
      ],
    };
  }, [gradebook, content, granularity]);

  const temporalChartOptions = useMemo<ChartOptions<'line'>>(() => {
    return {
      responsive: true,
      scales: {
        x: {
          type: 'category',
          title: { display: true, text: 'Date' },
        },
        y: {
          type: 'linear',
          beginAtZero: true,
          title: { display: true, text: 'Value' },
        },
      },
    };
  }, []);

  const handleGranularityChanged = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setGranularity(event.target.value as AverageGranularityOption);
  };

  return (
    <>
      <Line data={temporalChartData} options={temporalChartOptions} />
      <div className="row">
        <label className="label-normal">Frequency</label>
        <select onChange={handleGranularityChanged} defaultValue={granularity}>
          <option value={AverageGranularityOption.daily}>Daily</option>
          <option value={AverageGranularityOption.weekly}>Weekly</option>
          <option value={AverageGranularityOption.monthly}>Montly</option>
        </select>
      </div>
    </>
  );
};

export default ClassroomGrowthChart;
