import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import dictionaryService from './dictionaryService';

export class AIService {
  private static instance: AIService;

  public static get(): AIService {
    if (!AIService.instance) {
      AIService.instance = new AIService();
    }

    return AIService.instance;
  }

  gradeShortAnswer = async (
    question: string,
    user_response: string,
    img_url?: string,
  ): Promise<any> => {
    try {
      const response = await apiService.api.post(`/ai/grade-short-answer/`, {
        question: question,
        user_response: user_response,
        language: dictionaryService.language.name,
        img_url: img_url,
      });
      return {
        correct: response.data.correct,
        feedback: response.data.feedback.slice(0, 300),
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  generateNewcomerJson = async (
    topic: string,
    reading_difficulty: string,
    length: number,
  ): Promise<any[]> => {
    try {
      const response = await apiService.api.post(`/ai/generate/newcomer/`, {
        topic: topic,
        reading_difficulty: reading_difficulty,
        length: length,
      });
      return response.data.content;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  generateText = async (
    prompt: string,
    reading_difficulty?: string,
    key_words?: string[],
  ): Promise<any[]> => {
    try {
      const response = await apiService.api.post(`/ai/generate/reading/`, {
        prompt: prompt,
        reading_difficulty: reading_difficulty,
        key_words: key_words,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  convertTextToSpeech = async (
    text: string,
    langCode: string = 'en-US',
  ): Promise<any> => {
    try {
      const response = await apiService.api.post(
        `/ai/texttospeech/`,
        { text: text, langCode: langCode },
        { responseType: 'blob' },
      );

      // Create a Blob from the response data
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

      // Create a URL for the Blob
      const audioUrl = URL.createObjectURL(audioBlob);
      return audioUrl;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  convertSpeechToText = async (
    audio: Blob,
    langCode: string = 'en-US',
    long: boolean = false,
  ): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append('audio', audio);

      var response;
      if (long) {
        response = await apiService.api.post(
          `/ai/longspeechtotext/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
      } else {
        response = await apiService.api.post(`/ai/speechtotext/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      return response.data.transcript;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const aiService = AIService.get();
export default aiService;
