export default class QuestionResponse {
  public readonly id;
  public assignment_submission: string;
  public question: string;
  public response: string;
  public grade: number;
  public completed: boolean;
  public choice: string;
  public num_attempts: number;
  public student_profile_id?: string;
  public feedback?: string;
  public choice_index?: number;

  constructor(
    id: string,
    assignment_submission: string,
    question: string,
    response: string,
    grade: number,
    completed: boolean,
    choice: string,
    num_attempts: number = 0,
    student_profile_id?: string,
    feedback?: string,
    choice_index?: number,
  ) {
    this.id = id;
    this.assignment_submission = assignment_submission;
    this.question = question;
    this.response = response;
    this.grade = grade;
    this.completed = completed;
    this.choice = choice;
    this.num_attempts = num_attempts;
    this.student_profile_id = student_profile_id;
    this.feedback = feedback;
    this.choice_index = choice_index;
  }

  static fromQuestionResponse(questionResponse: QuestionResponse) {
    return new QuestionResponse(
      questionResponse.getId(),
      questionResponse.assignment_submission,
      questionResponse.question,
      questionResponse.response,
      questionResponse.grade,
      questionResponse.completed,
      questionResponse.choice,
      questionResponse.num_attempts,
      questionResponse.student_profile_id,
      questionResponse.feedback,
      questionResponse.choice_index,
    );
  }

  static fromServerQuestionResponse(
    questionResponse: Record<string, any>,
  ): QuestionResponse {
    return new QuestionResponse(
      questionResponse['id'],
      questionResponse['assignment_submission'],
      questionResponse['question'],
      questionResponse['response'],
      questionResponse['grade'],
      questionResponse['completed'],
      questionResponse['choice'],
      questionResponse['num_attempts'],
      questionResponse['student_profile_id'],
      questionResponse['feedback'],
      questionResponse['choice_index'],
    );
  }

  static generateDefault(): QuestionResponse {
    return new QuestionResponse('', '', '', '', 0, false, '');
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      assignment_submission: this.assignment_submission,
      question: this.question,
      response: this.response,
      grade: this.grade,
      completed: this.completed,
      choice: this.choice,
      feedback: this.feedback,
      student_profile_id: this.student_profile_id,
      num_attempts: this.num_attempts,
    };
  }
}
