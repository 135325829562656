import AssignmentSubmission from 'models/AssignmentSubmission';
import TeacherGradebook from 'models/TeacherGradebook';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import { useMemo } from 'react';
import contentService from 'services/contentService';

interface FlatGradebookProps {
  student: TeacherGradebookEntry;
  gradebook: TeacherGradebook;
}

const FlatGradebook: React.FC<FlatGradebookProps> = ({
  student,
  gradebook,
}) => {
  const content = contentService.getProgressReportContent('English');
  const classAverages = gradebook.averages;

  const tableHeaders = content.gradebook.headers;

  const studentMetrics = useMemo(() => {
    return Object.keys(classAverages.assignments)
      .filter((assignmentId) =>
        student.assignment_submissions.find(
          (sa) => sa.assignment === assignmentId,
        ),
      )
      .map((assignmentId) => {
        const sub = student.assignment_submissions.find(
          (sa) => sa.assignment === assignmentId,
        ) as AssignmentSubmission;
        const classAssignment = classAverages.assignments[assignmentId];
        return {
          assignmentId,
          grade: sub.grade.toFixed(2),
          avgGrade: classAssignment.grade.toFixed(2),
          correctness: sub.correctness_score.toFixed(2),
          avgCorrectness: classAssignment.correctness_score.toFixed(2),
          completion: sub.completion_score.toFixed(2),
          avgCompletion: classAssignment.completion_score.toFixed(2),
          keyWordAccuracy: sub.key_word_accuracy_score.toFixed(2),
          avgKeyWordAccuracy:
            classAssignment.key_word_accuracy_score.toFixed(2),
          completionTime: sub.completion_time.toFixed(2),
          avgCompletionTime: classAssignment.completion_time.toFixed(2),
        };
      });
  }, [classAverages, student]);

  return (
    <table className="missed-words-table">
      <thead>
        <tr>
          {tableHeaders.map((header: any) => (
            <th key={header}>
              <span className="label-small">{header}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {studentMetrics.map((row) => (
          <tr key={row.assignmentId}>
            <td>
              <span className="label-small">{row.assignmentId}</span>
            </td>
            <td>
              <span className="label-small">{row.grade}</span>
            </td>
            <td>
              <span className="label-small">{row.avgGrade}</span>
            </td>
            <td>
              <span className="label-small">{row.correctness}</span>
            </td>
            <td>
              <span className="label-small">{row.avgCorrectness}</span>
            </td>
            <td>
              <span className="label-small">{row.completion}</span>
            </td>
            <td>
              <span className="label-small">{row.avgCompletion}</span>
            </td>
            <td>
              <span className="label-small">{row.keyWordAccuracy}</span>
            </td>
            <td>
              <span className="label-small">{row.avgKeyWordAccuracy}</span>
            </td>
            <td>
              <span className="label-small">{row.completionTime}</span>
            </td>
            <td>
              <span className="label-small">{row.avgCompletionTime}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FlatGradebook;
