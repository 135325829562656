import ReadingUtils from 'utils/ReadingUtils';
import Classroom from './Classroom';
import { extractInnerTextFromHTML } from 'utils/fileUtils';
import CachingEngine from 'utils/CachingEngine';
import Question from './Question';

export default class Book {
  public id;
  public title: string;
  public author: string;
  public difficulty: number;
  public word_count: number;
  public cover_url: string;
  public genre: string;
  public classroom?: string;
  public is_public: boolean;
  public is_under_review: boolean;
  public html_url: string;
  public tags: Array<string>;
  public html_content?: string;
  public words?: string[];
  public audio_url?: string;
  public default_questions?: any[];
  public text?: string = '';
  public rich_text?: string = '';
  public json_content?: Record<any, any>;

  constructor(
    id: string,
    title: string,
    author: string,
    difficulty: number,
    word_count: number,
    cover_url: string,
    genre: string,
    tags: string[],
    classroom?: string,
    is_public: boolean = false,
    is_under_review: boolean = false,
    html_url: string = '',
    html_content?: string,
    words?: string[],
    audio_url?: string,
    default_questions?: any[],
    json_content?: Record<any, any>,
  ) {
    this.id = id;
    this.title = title;
    this.classroom = classroom;
    this.author = author;
    this.difficulty = difficulty;
    this.word_count = word_count;
    this.cover_url = cover_url;
    this.genre = genre;
    this.is_public = is_public;
    this.is_under_review = is_under_review;
    this.audio_url = audio_url;
    if (default_questions) {
      this.default_questions = default_questions.map((q, idx) => {
        return Question.fromServerQuestion({ ...q, index: idx + 1 });
      });
    }
    this.html_url = html_url;
    this.html_content = html_content;
    this.words = words;
    this.tags = tags;
    this.json_content = json_content;
  }

  public async getHtmlContent() {
    if (
      !this.html_url ||
      this.html_url === '' ||
      (this.html_content && this.html_content !== '')
    )
      return;
    // check if the content is cached
    const cachedContent = await CachingEngine.getData(`book-${this.id}`);
    if (cachedContent) {
      this.html_content = cachedContent;
    } else {
      try {
        const response = await fetch(this.html_url);

        if (!response.ok) {
          // Handle HTTP errors (non-2xx status codes)
          throw new Error(
            `HTTP error! Status: ${response.status} - ${response.statusText}`,
          );
        }

        this.html_content = await response.text();
      } catch (error) {
        // Log the error and take appropriate action
        console.error('Error fetching HTML content:', error);

        // Optionally, you can set a fallback value or notify the user
        // this.html_content = 'An error occurred while loading the content.';
      }
      // cache the content
      await CachingEngine.setData(`book-${this.id}`, this.html_content);
    }
    if (this.html_content) {
      this.words = this.getWords();
    }
  }

  public async getJsonContent() {
    if (!this.html_url || this.html_url === '' || this.json_content) return;
    // check if the content is cached
    const cachedContent = await CachingEngine.getData(`book-${this.id}`);
    if (cachedContent) {
      this.json_content = JSON.parse(cachedContent);
    } else {
      const response = await fetch(this.html_url);
      this.json_content = await response.json();
      // cache the content
      await CachingEngine.setData(`book-${this.id}`, this.html_content);
    }
  }

  static fromBook(book: Book): Book {
    return new Book(
      book.id,
      book.title,
      book.author,
      book.difficulty,
      book.word_count,
      book.cover_url,
      book.genre,
      book.tags,
      book.classroom,
      book.is_public,
      book.is_under_review,
      book.html_url,
      book.html_content,
      book.words,
      book.audio_url,
      book.default_questions,
      book.json_content,
    );
  }

  static fromServerBook(serverBook: Record<string, any>): Book {
    if (!serverBook['html_content'] || serverBook['html_content'] === '') {
      serverBook['html_content'] = serverBook['rich_text'];
    }

    return new Book(
      serverBook['id'],
      serverBook['title'],
      serverBook['author'],
      serverBook['difficulty'],
      serverBook['word_count'],
      serverBook['cover_url'],
      serverBook['genre'],
      serverBook['tags'],
      serverBook['classroom'],
      serverBook['is_public'],
      serverBook['is_under_review'],
      serverBook['html_url'],
      serverBook['html_content'],
      serverBook['words'],
      serverBook['audio_url'],
      serverBook['default_questions'],
      serverBook['json_content'],
    );
  }

  static generateDefault(classroom?: Classroom): Book {
    return new Book('', '', '', 0, 0, '', 'None', [], classroom?.getId());
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      title: this.title,
      author: this.author,
      difficulty: this.difficulty,
      word_count: this.word_count,
      cover_url: this.cover_url,
      genre: this.genre,
      tags: this.tags,
      classroom: this.classroom,
      is_public: this.is_public,
      is_under_review: this.is_under_review,
      html_url: this.html_url,
      html_content: this.html_content,
      audio_url: this.audio_url,
    };
  }

  public getText(): string {
    return extractInnerTextFromHTML(this.html_content);
  }

  public getWords(): string[] {
    const text = this.getText();
    return text
      .split(ReadingUtils.specialCharacterRegex)
      .filter((s) => s !== '')
      .map((s) =>
        ReadingUtils.stripSpecialCharsFromEndsOfString(s).toLowerCase(),
      );
  }
}
