import React from 'react';
import Book from 'models/Book';

import 'view/style/catalog/components/bookCard.css';

const menu_book: string = require('assets/icons/menu_book_gray.svg').default;

interface BookCardProps {
  book: Book;
  onSelect?: (book: Book) => void;
}

const BookCard: React.FC<BookCardProps> = ({ book, onSelect = () => {} }) => {
  return (
    <div className="book-card" onClick={() => onSelect(book)}>
      <div className="book-card-cover">
        {book.cover_url ? (
          <img src={book.cover_url} alt="" />
        ) : (
          <img className="icon" src={menu_book} alt="" />
        )}
      </div>
      <div className="book-label">
        <label className="label-small">{book.title}</label>
        <label className="label-small">{book.difficulty}</label>
      </div>
    </div>
  );
};

export default BookCard;
