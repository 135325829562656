import React, { useEffect, useState } from 'react';
import Button from 'view/components/buttons/Button';
import IconButton from 'view/components/buttons/IconButton';
import ReadingFrame from 'view/components/common/ReadingFrame';
import Question from 'models/Question';
import StudentQuestion from 'models/StudentQuestion';
import QuestionResponse from 'models/QuestionResponse';
import TranscribeFrame from './TranscribeFrame';
import FillInBlankFrame from './FillInBlankFrame';
import QuestionFrame from './QuestionFrame';
import ConversationFrame from './ConversationFrame';
import ReadAloudFrame from './ReadAloudFrame';
import { QuestionType } from 'utils/types';
import CachingEngine from 'utils/CachingEngine';

import 'view/style/student/components/newcomer/multipartAssignment.css';

const check = require('assets/icons/check-solid.svg').default;
const pencil = require('assets/icons/pencil.svg').default;

type MultiPartAssignmentProps = {
  questions: StudentQuestion[];
  teacherView?: boolean;
  showFeedback?: boolean;
  onCompletion?: () => void;
  onUpdateResponse: (question: Question, response: QuestionResponse) => void;
  onMissedWord?: (
    word: string,
    index: number,
    known: boolean,
    question?: Question,
  ) => Promise<void>;
};

const MultipartAssignment: React.FC<MultiPartAssignmentProps> = ({
  questions,
  teacherView = false,
  showFeedback = true,
  onCompletion,
  onUpdateResponse,
  onMissedWord,
}) => {
  const [mutableQuestions, setMutableQuestions] =
    useState<StudentQuestion[]>(questions);
  const [activeQuestionIdx, setActiveFrameIdx] = useState<number>(0);
  const [responses, setResponses] = useState<QuestionResponse[]>(
    questions.map((q) => q.response),
  );

  useEffect(() => {
    return () => {
      questions.map((q) =>
        CachingEngine.deleteData(`${q.question.index}-audio`),
      );
    };
  }, [questions]);

  const handlePrevious = () => {
    setActiveFrameIdx((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveFrameIdx((prev) =>
      Math.min(prev + 1, mutableQuestions.length - 1),
    );
  };

  const handleCheck = () => {
    if (
      !mutableQuestions ||
      mutableQuestions[activeQuestionIdx].question.check ||
      (responses[activeQuestionIdx].choice === null &&
        responses[activeQuestionIdx].response === '' &&
        mutableQuestions[activeQuestionIdx].question.question_type !==
          QuestionType.READALOUD)
    )
      return;

    const updatedQuestions = [...questions];
    updatedQuestions[activeQuestionIdx].question.check = true;
    setMutableQuestions(updatedQuestions);

    var updatedResponses = [...responses];
    updatedResponses[activeQuestionIdx] = QuestionResponse.fromQuestionResponse(
      updatedResponses[activeQuestionIdx],
    );
    updatedResponses[activeQuestionIdx].num_attempts += 1;

    setResponses(updatedResponses);

    if (
      updatedResponses.every((response) => response.num_attempts >= 1) &&
      onCompletion
    ) {
      onCompletion();
    }
  };

  const updateResponse = (
    response: QuestionResponse,
    resetCheck: boolean = true,
  ) => {
    var updatedResponses = [...responses];
    updatedResponses[activeQuestionIdx] = response;
    setResponses(updatedResponses);

    if (
      response.completed &&
      (questions[activeQuestionIdx].question.check ||
        questions[activeQuestionIdx].question.question_type ===
          QuestionType.READALOUD)
    ) {
      onUpdateResponse(questions[activeQuestionIdx].question, response);
    }

    if (
      questions[activeQuestionIdx].question.question_type ===
        QuestionType.READALOUD &&
      onCompletion
    ) {
      onCompletion();
    }

    if (!resetCheck) return;

    const updatedQuestions = [...questions];
    updatedQuestions[activeQuestionIdx].question.check = false;
    setMutableQuestions(updatedQuestions);
  };

  const gradeResponse = () => {
    if (!teacherView) return;
    onUpdateResponse(
      questions[activeQuestionIdx].question,
      responses[activeQuestionIdx],
    );
  };

  if (!mutableQuestions) return null;

  return (
    <div className="reading-container">
      <ReadingFrame>
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'choice' && (
          <QuestionFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            showFeedback={showFeedback}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'text' && (
          <QuestionFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            showFeedback={showFeedback}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'readAloud' && (
          <ReadAloudFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            onMissedWord={onMissedWord}
            showFeedback={showFeedback}
          />
        )}
        {(mutableQuestions[activeQuestionIdx].question.question_type ===
          'conversation' ||
          mutableQuestions[activeQuestionIdx].question.question_type ===
            'respond') && (
          <ConversationFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            showFeedback={showFeedback}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'transcribe' && (
          <TranscribeFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            showFeedback={showFeedback}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'listening' && (
          <QuestionFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            showFeedback={showFeedback}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'fillInBlank' && (
          <FillInBlankFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            teacherView={teacherView}
            updateResponse={updateResponse}
            showFeedback={showFeedback}
          />
        )}
      </ReadingFrame>
      <div className="row">
        {responses[activeQuestionIdx].num_attempts >= 1 ? (
          <img src={check} alt="Attempted" />
        ) : (
          <img src={pencil} alt="In progress" />
        )}
        {activeQuestionIdx > 0 && (
          <IconButton onClick={handlePrevious} icon="back" />
        )}
        {teacherView ? (
          <Button
            type="go"
            onClick={gradeResponse}
            label="Grade"
            text="Grade"
          />
        ) : (
          <Button
            type="go"
            onClick={handleCheck}
            label="Submit"
            text="submit"
          />
        )}
        {activeQuestionIdx < responses.length - 1 &&
          (responses[activeQuestionIdx].completed || teacherView) && (
            <IconButton onClick={handleNext} icon="next" />
          )}
        <label className="label-small">Question {activeQuestionIdx + 1}</label>
      </div>
    </div>
  );
};

export default MultipartAssignment;

export interface FrameProps {
  question: Question;
  response: QuestionResponse;
  teacherView?: boolean;
  showFeedback?: boolean;
  updateResponse: (response: QuestionResponse, resetCheck?: boolean) => void;
}
