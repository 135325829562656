import React, { useState } from 'react';
import { DisplayMissedWord } from 'utils/types';
import TeacherGradebook from 'models/TeacherGradebook';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import ClassComparisonChart from './ClassComparisonChart';
import FlatGradebook from './FlatGradebook';
import MissedWordTable from '../MissedWordTable';
import StudentGrowthChart from './StudentGrowthChart';

interface StudentAnalyticsProps {
  missedWords: DisplayMissedWord[];
  student: TeacherGradebookEntry;
  gradebook: TeacherGradebook;
}

const StudentAnalytics: React.FC<StudentAnalyticsProps> = ({
  missedWords,
  student,
  gradebook,
}) => {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const tabLabels = ['Overall', 'Improvement', 'Gradebook', 'Missed Words'];
  const tabContents = [
    <ClassComparisonChart student={student} gradebook={gradebook} />,
    <StudentGrowthChart student={student} />,
    <FlatGradebook student={student} gradebook={gradebook} />,
    <MissedWordTable missedWords={missedWords} />,
  ];

  return (
    <div className="graph-container">
      <div className="student-insights">
        <div className="tab-headers">
          {tabLabels.map((label, idx) => (
            <span
              key={`tab-header-label-${idx}-${label}`}
              className={`tab-header-label ${
                idx === selectedIdx ? 'active-label' : ''
              }`}
              onClick={() => setSelectedIdx(idx)}
            >
              {label}
            </span>
          ))}
        </div>
        {tabContents[selectedIdx]}
      </div>
    </div>
  );
};

export default StudentAnalytics;
