import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useAssignmentContext } from 'contexts/AssignmentContext';
import Book from 'models/Book';
import studentService from 'services/studentService';
import MissedWord from 'models/MissedWord';
import Modal from 'view/components/common/Modal';
import {
  QuestionViewerState,
  QuestionsViewer,
} from 'view/components/student/QuestionsViewer';
import StudentQuestion from 'models/StudentQuestion';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import AssignmentSettings from 'view/components/student/assignment/AssignmentSettings';
import AssignmentContent from 'view/components/student/assignment/AssignmentContent';
import teacherService from 'services/teacherService';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import Question from 'models/Question';
import QuestionResponse from 'models/QuestionResponse';
import { QuestionType } from 'utils/types';
import AssignmentViewerSidebar from 'view/components/student/assignment/AssignmentViewerSidebar';

import 'view/style/student/assignment.css';

export const TeacherAssignmentSubmissionPage: React.FC = () => {
  const { classroom } = useClassroomContext();
  const { assignment, setAssignment } = useAssignmentContext();
  const { classroomId, assignmentId, submissionId } = useParams();
  const [book, setBook] = useState<Book>();
  const [missedWords, setMissedWords] = useState<MissedWord[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<StudentQuestion[]>();
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const [isReading, setIsReading] = useState<boolean>(false);
  const [isOverdue, setIsOverdue] = useState<boolean>(false);
  // const [useDyslexic, setUseDyslexic] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<boolean>(false);
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classroom || !assignmentId || !submissionId || book || questions)
      return;
    makeApiCall(teacherService.getAssignmentById, assignmentId).then(
      (respAssignment) => {
        makeApiCall(teacherService.getSubmissionById, submissionId)
          .then((respSubmission) => {
            setAssignment(
              new StudentGradebookEntry(
                respAssignment.assignment,
                respSubmission.submission,
              ),
            );
            setBook(respAssignment.book);
            setQuestions(respSubmission.questions);
            setIsOverdue(
              new Date(respAssignment.assignment.due_date) < new Date(),
            );
          })
          .catch((error) => navigate(-1));
      },
    );
  }, [
    assignment,
    book,
    questions,
    classroom,
    classroomId,
    assignmentId,
    submissionId,
    setAssignment,
    setBook,
    setQuestions,
    navigate,
    makeApiCall,
  ]);

  useEffect(() => {
    if (missedWords.length > 0 || !assignment || !isLoading) return;
    setIsLoading(true);
    if (
      assignment.assignment.assignment_type === 'Flashcards' ||
      assignment.assignment.assignment_type === 'Pronounce'
    ) {
      if (assignment.assignment.key_words.length) {
        setMissedWords(
          assignment.assignment.key_words.map(
            (kw) => new MissedWord('-1', kw, '-1', 0, false, true),
          ),
        );
      } else {
        makeApiCall(
          studentService.getStudentMissedWords,
          assignment?.assignment_submission.student_profile,
        )
          .then((respWords) =>
            setMissedWords(respWords.slice(0, assignment.assignment.length)),
          )
          .catch((error) => alert(error.message))
          .finally(() => setIsLoading(false));
      }
    } else {
      makeApiCall(
        studentService.getAssignmentMissedWords,
        assignment.assignment_submission,
      )
        .then((respWords) => {
          setMissedWords(respWords);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setIsLoading(false);
        });
    }
  }, [assignment, missedWords, isLoading, makeApiCall]);

  const handleExitAssignment = () => {
    setAssignment(null);
    navigate(-1);
  };

  const handleCloseQuestions = () => {
    setViewQuestions(false);
  };

  const handleViewQuestions = () => {
    setViewQuestions(true);
  };

  const handleQuestionResponseChanged = (
    question: Question,
    response: QuestionResponse,
  ) => {
    if (
      !questions ||
      ((response.response === '' || !response.response) &&
        (!response.choice || response.choice === '') &&
        question.question_type !== QuestionType.READALOUD)
    )
      return;

    const updatedQuestion = new StudentQuestion(
      Question.fromQuestion(question),
      QuestionResponse.fromQuestionResponse(response),
    );

    // Make the API call to save the updated response
    makeApiCall(studentService.updateQuestionResponse, updatedQuestion.response)
      .then((resp) => {
        // Update the questions array immutably, preserving order
        setQuestions((prevQuestions) =>
          prevQuestions!.map((q) =>
            q.question.getId() === question.getId() ? updatedQuestion : q,
          ),
        );
      })
      .catch((error) => alert(error.message));

    // Return the updated question object
    return updatedQuestion;
  };

  if (!assignment) return <AppPage />;

  return (
    <AppPage>
      <div className="app-page-content">
        <AssignmentViewerSidebar
          assignment={assignment}
          missedWords={missedWords}
          useDyslexic={false}
          onExitAssignment={handleExitAssignment}
          onViewSettings={() => setViewSettings(true)}
          onToggleDyslexic={() => {}}
        />
        <AssignmentContent
          assignment={assignment}
          book={book}
          missedWords={missedWords}
          questions={questions}
          isReading={isReading}
          useDyslexic={false}
          setIsReading={setIsReading}
          onBookCompleted={() => {}}
          onStopReading={() => {}}
          onMissedWord={async () => {}}
          onViewQuestions={handleViewQuestions}
          onListeningCompleted={() => {}}
          onListeningPaused={() => {}}
          onMultiPartCompleted={() => {}}
          onQuestionResponseChanged={handleQuestionResponseChanged}
          teacherView={true}
        />
        {questions && (
          <Modal isOpen={viewQuestions} onClose={handleCloseQuestions}>
            <QuestionsViewer
              state={
                !isOverdue
                  ? QuestionViewerState.InProgress
                  : assignment?.assignment_submission.questions_grade
                  ? QuestionViewerState.Graded
                  : QuestionViewerState.Locked
              }
              grade={assignment?.assignment_submission.questions_grade}
              questions={questions}
              onExit={handleCloseQuestions}
            />
          </Modal>
        )}
        <Modal isOpen={viewSettings} onClose={() => setViewSettings(false)}>
          <AssignmentSettings />
        </Modal>
      </div>
    </AppPage>
  );
};

export default TeacherAssignmentSubmissionPage;
