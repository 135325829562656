import React from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import AppSidebar from 'view/components/common/Sidebar';
import { MissedWordList } from '../MissedWordList';
import LanguageSelector from 'view/components/common/LanguageSelector';
import { formatDate } from 'utils/utils';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import MissedWord from 'models/MissedWord';
import contentService from 'services/contentService';
import ActionButton from 'view/components/buttons/ActionButton';

const calendar: string = require('assets/icons/event.svg').default;
const graduation: string = require('assets/icons/graduation_cap.svg').default;
const eye: string = require('assets/icons/eye-solid.svg').default;

interface AssignmentViewerSidebarProps {
  assignment: StudentGradebookEntry;
  missedWords: MissedWord[];
  useDyslexic: boolean;
  onExitAssignment: () => void;
  onViewSettings: () => void;
  onToggleDyslexic: () => void;
  onSelect?: () => void;
}

const AssignmentViewerSidebar: React.FC<AssignmentViewerSidebarProps> = ({
  assignment,
  missedWords,
  useDyslexic,
  onExitAssignment,
  onViewSettings,
  onToggleDyslexic,
  onSelect,
}) => {
  const pageContent = contentService.getAssignmentSubmissionPage();

  return (
    <AppSidebar onExit={onExitAssignment} onViewSettings={onViewSettings}>
      <>
        <div className="sidebar-content-data">
          <div className="row">
            <img src={calendar} alt="" />
            <label className="label-small">
              {pageContent.sidebar.due.label}
            </label>
            <AudioAssistanceButton text="Due Date" />
          </div>
          <label className="label-large">
            {assignment.assignment.due_date
              ? formatDate(new Date(assignment.assignment.due_date))
              : 'No due date'}
          </label>
        </div>
        {assignment.assignment.start_date && (
          <div className="sidebar-content-data">
            <label className="label-small">
              {pageContent.sidebar.available.label}{' '}
              {formatDate(new Date(assignment.assignment.start_date))}
            </label>
          </div>
        )}
        <LanguageSelector />
        <div className="row spaced">
          <div className="row">
            <img src={graduation} alt="" />
            <label className="label-small">Learned Words</label>
            <AudioAssistanceButton text="Learned words" />
          </div>
        </div>
        <MissedWordList missedWords={missedWords} />
        <div className="row spaced">
          <div className="row">
            <img src={eye} alt="" />
            <label className="label-small">Change to dyslexic font</label>
            <AudioAssistanceButton text="Change to dyslexic font" />
          </div>
          <input
            type="checkbox"
            checked={useDyslexic}
            onChange={onToggleDyslexic}
          />
        </div>
        {onSelect && <ActionButton label="Create a copy" onClick={onSelect} />}
      </>
    </AppSidebar>
  );
};

export default AssignmentViewerSidebar;
