import React from 'react';
import 'view/style/student/components/newcomer/multipartAssignment.css';
import MultipartAssignment from '../newcomers/MultipartAssignment';
import PronouncePractice from '../practice/PronouncePractice';
import FlashcardPractice from '../practice/FlashcardPractice';
import SpeakingAssessment from './SpeakingAssessment';
import ListeningAssessment from './ListeningAssessment';
import { BookListener } from './BookListener';
import { BookReader } from 'view/components/common/BookReader';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import contentService from 'services/contentService';
import Book from 'models/Book';
import MissedWord from 'models/MissedWord';
import StudentQuestion from 'models/StudentQuestion';
import QuestionResponse from 'models/QuestionResponse';
import Question from 'models/Question';

type AssignmentContentProps = {
  assignment: StudentGradebookEntry;
  book?: Book;
  missedWords: MissedWord[];
  questions?: StudentQuestion[];
  isReading: boolean;
  useDyslexic: boolean;
  teacherView?: boolean;
  setIsReading: (isReading: boolean) => void;
  onBookCompleted: () => void;
  onStopReading: (index: number) => void;
  onMissedWord: (
    word: string,
    index: number,
    known: boolean,
    question?: Question,
  ) => Promise<void>;
  onViewQuestions: () => void;
  onListeningCompleted: () => void;
  onListeningPaused: (timeStamp: number, duration: number) => void;
  onMultiPartCompleted: () => void;
  onQuestionResponseChanged: (
    question: Question,
    response: QuestionResponse,
  ) => StudentQuestion | undefined;
};

const AssignmentContent: React.FC<AssignmentContentProps> = ({
  assignment,
  book,
  missedWords,
  questions,
  isReading,
  useDyslexic,
  teacherView = false,
  setIsReading,
  onBookCompleted,
  onStopReading,
  onMissedWord,
  onViewQuestions,
  onListeningCompleted,
  onListeningPaused,
  onMultiPartCompleted,
  onQuestionResponseChanged,
}) => {
  const pageContent = contentService.getAssignmentSubmissionPage();
  return (
    <div className="app-main-content">
      <h1>{assignment?.assignment.title}</h1>
      {!book && !assignment && pageContent.error_messages.no_book}
      {book && assignment?.assignment.assignment_type === 'Default' && (
        <div className="reading-container">
          <BookReader
            book={book}
            startIndex={assignment.assignment_submission.word_index}
            missedWords={missedWords}
            isReading={isReading}
            missedWordInterval={assignment.assignment.missed_word_interval}
            showMissedWordPopup={assignment.assignment.show_missed_word_popup}
            dyslexicMode={useDyslexic}
            setIsReading={setIsReading}
            onCompletion={onBookCompleted}
            onStopReading={onStopReading}
            onMissedWord={onMissedWord}
            onViewQuestions={
              questions && questions?.length > 0 ? onViewQuestions : undefined
            }
            teacherView={teacherView}
            hideQuestions={!teacherView}
          />
        </div>
      )}
      {book && assignment?.assignment.assignment_type === 'Read-Aloud' && (
        <BookListener
          book={book}
          isReading={isReading}
          missedWords={missedWords}
          startTime={assignment.assignment_submission.time_stamp}
          dyslexicMode={useDyslexic}
          setIsReading={setIsReading}
          onCompletion={onListeningCompleted}
          onStopReading={onListeningPaused}
          onViewQuestions={
            questions && questions?.length > 0 ? onViewQuestions : undefined
          }
          onMissedWord={onMissedWord}
          teacherView={teacherView}
        />
      )}
      {book && assignment?.assignment.assignment_type === 'Listening' && (
        <ListeningAssessment
          book={book}
          isReading={isReading}
          startTime={assignment.assignment_submission.time_stamp}
          setIsReading={setIsReading}
          onStopReading={onListeningPaused}
          onCompletion={onListeningCompleted}
          onViewQuestions={
            questions && questions?.length > 0 ? onViewQuestions : undefined
          }
          teacherView={teacherView}
        />
      )}
      {book && assignment?.assignment.assignment_type === 'Speaking' && (
        <SpeakingAssessment
          book={book}
          isReading={isReading}
          startIndex={assignment.assignment_submission.word_index}
          missedWords={missedWords}
          setIsReading={setIsReading}
          onStopReading={onStopReading}
          onCompletion={onBookCompleted}
          onMissedWord={onMissedWord}
          onViewQuestions={
            questions && questions?.length > 0 ? onViewQuestions : undefined
          }
          teacherView={teacherView}
        />
      )}
      {assignment?.assignment.assignment_type === 'Flashcards' && (
        <FlashcardPractice
          missedWords={missedWords}
          onProgress={() => {}}
          onCompletion={onListeningCompleted}
        />
      )}
      {assignment?.assignment.assignment_type === 'Pronounce' && (
        <PronouncePractice
          missedWords={missedWords}
          onProgress={() => {}}
          onCompletion={onListeningCompleted}
        />
      )}
      {assignment?.assignment.assignment_type === 'Multi-Part' && questions && (
        <MultipartAssignment
          questions={questions}
          onCompletion={onMultiPartCompleted}
          onUpdateResponse={onQuestionResponseChanged}
          onMissedWord={onMissedWord}
          teacherView={teacherView}
          showFeedback={
            assignment.assignment.show_feedback ||
            (assignment.assignment.due_date
              ? new Date(assignment.assignment.due_date) < new Date()
              : false)
          }
        />
      )}
    </div>
  );
};

export default AssignmentContent;
