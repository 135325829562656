import React, { ReactNode, useState } from 'react';
import { useFormContext } from 'contexts/FormContext';

import 'view/style/common/components/horizontalCarousel.css';

interface HorizontalCarouselProps {
  textContent?: ReactNode[];
  pages: ReactNode[];
  pageSubmitCallbacks?: (((formData: any) => Promise<any>) | undefined)[];
  onSubmit?: (formData: any) => void;
}

const FLIP_ANIMATION_DURATION = 800; // in ms
const HALF_FLIP_DURATION = FLIP_ANIMATION_DURATION / 2;

const HorizontalCarousel: React.FC<HorizontalCarouselProps> = ({
  textContent,
  pages,
  pageSubmitCallbacks,
  onSubmit = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [flipping, setFlipping] = useState(false);
  const [flipDirection, setFlipDirection] = useState<'next' | 'prev' | null>(
    null,
  );
  const { formData } = useFormContext();

  // Prevent concurrent flips and update the content mid-animation.
  const initiateFlip = (newPage: number, direction: 'next' | 'prev') => {
    if (flipping) return; // ignore clicks during an ongoing flip
    setFlipDirection(direction);
    setFlipping(true);

    // Update the page content halfway through the animation.
    setTimeout(() => {
      setCurrentPage(newPage);
    }, HALF_FLIP_DURATION);
  };

  const handleNext = () => {
    const callback = pageSubmitCallbacks?.[currentPage];
    const newPage = currentPage < pages.length - 1 ? currentPage + 1 : 0;

    if (callback) {
      callback(formData)
        .then(() => {
          initiateFlip(newPage, 'next');
          // If we’re on the last page, call onSubmit after the flip.
          if (currentPage === pages.length - 1) {
            onSubmit(formData);
          }
        })
        .catch((error) => {
          console.error('Error in page callback:', error);
          setErrorMessage(error.message);
        });
    } else {
      initiateFlip(newPage, 'next');
      if (currentPage === pages.length - 1) {
        onSubmit(formData);
      }
    }
  };

  const handlePrev = () => {
    const newPage = currentPage === 0 ? pages.length - 1 : currentPage - 1;
    initiateFlip(newPage, 'prev');
  };

  // When the CSS animation completes, clear the flipping state.
  const handleAnimationEnd = () => {
    setFlipping(false);
    setFlipDirection(null);
  };

  return (
    <>
      {textContent && textContent[currentPage]}
      <div className="horizontal-carousel">
        <div
          className={`main-page ${
            flipping
              ? flipDirection === 'next'
                ? 'flip-next'
                : 'flip-prev'
              : ''
          }`}
          onAnimationEnd={handleAnimationEnd}
          aria-live="polite"
          aria-atomic="true"
        >
          {pages[currentPage]}
        </div>
        <div
          className="prev-page"
          onClick={handlePrev}
          aria-label="Previous Page"
          role="button"
          tabIndex={0}
        >
          {currentPage === 0 ? pages[pages.length - 1] : pages[currentPage - 1]}
        </div>
        <div
          className="next-page"
          onClick={handleNext}
          aria-label="Next Page"
          role="button"
          tabIndex={0}
        >
          {currentPage === pages.length - 1 ? pages[0] : pages[currentPage + 1]}
        </div>

        {errorMessage && (
          <span className="label-normal danger">{errorMessage}</span>
        )}
      </div>
    </>
  );
};

export default HorizontalCarousel;
