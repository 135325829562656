import { QuestionType } from 'utils/types';
import MultipleChoiceOption from './MultipleChoiceOption';

export default class Question {
  public readonly id;
  public assignment: string;
  public text: string;
  public index: number;
  public max_points: number;
  public question_type: QuestionType;
  public choices?: MultipleChoiceOption[];
  public check?: boolean;
  public image_url?: string;
  public prompt?: string;
  public image_file?: File;

  constructor(
    id: string,
    assignment: string,
    text: string,
    index: number,
    max_points: number = 100,
    question_type: QuestionType = QuestionType.SHORTANSWER,
    choices?: MultipleChoiceOption[],
    check?: boolean,
    image_url?: string,
    prompt?: string,
    image_file?: File,
  ) {
    this.id = id;
    this.assignment = assignment;
    this.text = text;
    this.index = index;
    this.max_points = max_points;
    this.question_type = question_type;
    if (choices) {
      this.choices = choices.sort((c1, c2) => c1.index - c2.index);
    }
    this.check = check;
    this.image_url = image_url;
    this.prompt = prompt;
    this.image_file = image_file;
  }

  static fromQuestion(question: Question) {
    return new Question(
      question.id,
      question.assignment,
      question.text,
      question.index,
      question.max_points,
      question.question_type,
      question.choices?.map((c) =>
        MultipleChoiceOption.fromMultipleChoiceOption(c),
      ),
      question.check,
      question.image_url,
      question.prompt,
      question.image_file,
    );
  }

  static fromServerQuestion(question: Record<string, any>): Question {
    if (question['choices']) {
      question['choices'] = question['choices'].map((c: MultipleChoiceOption) =>
        MultipleChoiceOption.fromServerOption(c),
      );
    }
    return new Question(
      question['id'],
      question['assignment'],
      question['text'],
      question['index'],
      question['max_points'],
      question['question_type'],
      question['choices'],
      question['check'],
      question['image_url'],
      question['prompt'],
      question['image_file'],
    );
  }

  public getId(): string {
    return this.id;
  }

  static generateDefault(
    index: number = 0,
    assignmentId: string = '',
  ): Question {
    return new Question('0', assignmentId, '', index, 100);
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      assignment: this.assignment,
      text: this.text,
      index: this.index,
      max_points: this.max_points,
      question_type: this.question_type,
      choices: this.choices?.map((c) => c.toJSON()),
      check: this.check,
      image_url: this.image_url,
      prompt: this.prompt,
      image_file: this.image_file,
    };
  }
}
