import React, { useState, useEffect } from 'react';
import { Slider } from '@mui/material';

import 'view/style/common/components/filter.css';

const arrow_drop_down: string =
  require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

export interface FilterOption {
  is_active: boolean;
  value: string;
  alias?: string;
}

interface FilterProps {
  type: 'range' | 'multichoice' | 'onechoice';
  label: string;
  options?: FilterOption[];
  max?: number;
  rangeInput?: Array<number>;
  onRangeChange?: (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => void;
  onOptionChange?: (checked: boolean, option: FilterOption) => void;
}

export const Filter: React.FC<FilterProps> = ({
  type,
  label,
  options = [],
  max,
  rangeInput,
  onRangeChange,
  onOptionChange,
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(true);

  const handleAllChange = (checked: boolean) => {
    handleOptionChange(checked, {
      value: 'All',
      is_active: checked,
    });

    setAllSelected((prev) => !prev);
  };

  // Handle individual option change
  const handleOptionChange = (checked: boolean, option: FilterOption) => {
    onOptionChange?.(checked, option);

    // if (type === 'multichoice') {
    //   const areAllSelected = options.every(
    //     (opt) => opt.is_active || (opt.value === option.value && checked),
    //   );
    //   setAllSelected(areAllSelected);
    // }
  };

  // Initialize or update "All" checkbox based on options
  useEffect(() => {
    if (type === 'multichoice') {
      const allChecked = options.every((option) => option.is_active);
      setAllSelected(allChecked);
    }
  }, [options, type]);

  return (
    <div className="collapsable-filter">
      <div
        className="sidebar-content-label"
        onClick={() => setExpand((prev) => !prev)}
      >
        <img src={expand ? arrow_drop_down : arrow_right} alt="dropdown" />
        <label className="label-small">{label}</label>
      </div>
      {expand && (
        <div className="filter-content">
          {type === 'range' && rangeInput && onRangeChange && max && (
            <>
              {rangeInput[0]}
              <Slider
                getAriaLabel={() => `${label} range`}
                max={max}
                defaultValue={rangeInput}
                onChange={onRangeChange}
                valueLabelDisplay="auto"
                getAriaValueText={(value: number, index: number) =>
                  `${value === 0 ? 'Min' : 'Max'} value set to ${index}`
                }
                className="slider"
              />
              {rangeInput[1]}
            </>
          )}
          {type === 'multichoice' && options.length > 0 && (
            <>
              <div className="assignment-sidebar-text">
                <input
                  type="checkbox"
                  id="all"
                  checked={allSelected}
                  onChange={(e) => handleAllChange(e.target.checked)}
                />
                <label htmlFor="all" className="label-small">
                  All
                </label>
              </div>
              {options.map((option) => (
                <div
                  key={`option-${option.value}`}
                  className="assignment-sidebar-text"
                >
                  <input
                    type="checkbox"
                    id={option.value}
                    checked={option.is_active}
                    onChange={(event) =>
                      handleOptionChange(event.target.checked, option)
                    }
                  />
                  <label htmlFor={option.value} className="label-small">
                    {option.value}
                  </label>
                </div>
              ))}
            </>
          )}
          {type === 'onechoice' &&
            options.length > 0 &&
            options.map((option) => (
              <div
                key={`option-${option.value}`}
                className="assignment-sidebar-text"
              >
                <input
                  type="radio"
                  id={option.value}
                  checked={option.is_active}
                  name={label}
                  onChange={(event) =>
                    onOptionChange?.(event.target.checked, option)
                  }
                />
                <label htmlFor={option.value} className="label-small">
                  {option.value}
                </label>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
