import React from 'react';

import 'view/style/common/components/statusIcon.css';

const ALERT_ICON: string = require('assets/icons/error_outline.svg').default;
const EVENT_ICON: string = require('assets/icons/event.svg').default;
const TODO_ICON: string = require('assets/icons/menu_book.svg').default;
const DONE_ICON: string = require('assets/icons/check_circle.svg').default;
const LISTEN_ICON: string = require('assets/icons/listen.svg').default;
const VIEW_ICON: string = require('assets/icons/eye-solid.svg').default;
const WRONG_ICON: string = require('assets/icons/xmark-solid.svg').default;
const EDIT_ICON: string = require('assets/icons/settings.svg').default;

const ICONS: Record<string, string> = {
  alert: ALERT_ICON,
  event: EVENT_ICON,
  todo: TODO_ICON,
  done: DONE_ICON,
  listening: LISTEN_ICON,
  view: VIEW_ICON,
  wrong: WRONG_ICON,
  edit: EDIT_ICON,
};

const COLORS: Record<string, string> = {
  alert: '#B1243F',
  event: '#A35D16',
  todo: '#447D00',
  done: '#756F6D',
  listening: '#016d89',
  view: '#756F6D',
  wrong: '#B1243F',
  edit: '#756F6D',
};

interface StatusIconProps {
  type: string;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const StatusIcon: React.FC<StatusIconProps> = ({ type, text, onClick }) => {
  const icon = ICONS[type];
  const color = COLORS[type];

  return (
    <div
      className={`status-icon ${onClick ? 'clickable' : ''}`}
      style={{ color: color }}
      onClick={onClick}
    >
      <img src={icon} alt="" />
      <span className="label-small">{text}</span>
    </div>
  );
};

export default StatusIcon;
